/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { ListGroup, Button, Offcanvas } from 'react-bootstrap';


export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            user: null,
            isModerator: false,
            show: false,
        };
    }
    handleClose = () => { this.setState({ show: false, }) };
    handleShow = () => {
        this.setState({ show: true, })
    };
    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user, roles] = await Promise.all([authService.isAuthenticated(), authService.getUser(), authService.GetUserRoles()])
        roles !== 'user' && roles.map((role) => {
            if (role === 'moderator') {
                this.setState({ isModerator: true, });
            }
        });
        this.setState({
            isAuthenticated,
            user: user
        });
    }

    render() {
        const { isAuthenticated, user } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = `${ApplicationPaths.LogOut}`;
            const logoutState = { local: true };
            return this.authenticatedView(user, profilePath, logoutPath, logoutState);
        }
    }

    authenticatedView(user, profilePath, logoutPath, logoutState) {
        return (
            <>

                <Button variant="none" onClick={this.handleShow}>
                    {user && user.picture !== null && user.picture !== undefined ?

                        <img src={user.picture} alt="hugenerd" width="40" height="40" className="rounded-circle" /> :
                        <div style={{ width: 40, height: 40 }} className="d-flex bg-primary border-primary text-white fw-bold rounded-circle align-content-center align-items-center justify-content-center" >{`${user.name[0]} ${user.family_name[0]}`}</div>
                    }
                    {/*<span className="d-none d-sm-inline mx-1">{user && user.name}</span>*/}
                </Button>

                <Offcanvas show={this.state.show} onHide={this.handleClose} placement='end'  >
                    <Offcanvas.Header closeButton className="bg-secondary ">
                        <div className="d-flex align-content-center align-items-center justify-content-center text-white">
                            <div>
                                {user && user.picture !== null && user.picture !== undefined ?

                                    <img src={user.picture} alt="hugenerd" width="70" height="70" className="rounded" /> :
                                    <div style={{ width: 70, height: 70 }} className="d-flex bg-white border-white text-primary fw-bold rounded align-content-center align-items-center justify-content-center" >{`${user.name[0]} ${user.family_name[0]}`}</div>

                                }
                            </div>
                            <div className="ms-2">
                                <span >{user.name}  {user.family_name}</span>
                                <span  > {user.email}</span>
                            </div>
                        </div>
                    </Offcanvas.Header>
                    <Offcanvas.Body>

                        {this.state.isModerator &&
                            <div className="pb-2 mb-3 border-bottom">
                                <Link to="/moderator/business/requests" className="dropdown-item">Moderator</Link>
                            </div>
                        }
                        <div>
                            <Link to="/account/manage" className="dropdown-item">Manage Account</Link>
                        </div>
                        <div>
                            <Link to={logoutPath} state={logoutState} className="dropdown-item">Logout</Link>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>

            </>);
    }
    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Login</NavLink>
            </NavItem>
        </Fragment>);
    }
}
