/* eslint-disable no-const-assign */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Image, Badge, Alert, Table, Row, Col, InputGroup, Form } from 'react-bootstrap';
import apiAccesses from '../Apis/Accesses';
import { format, parseISO } from 'date-fns';
import PageLoading from '../Helpars/PageLoading';
import HelparPagination from '../Helpars/Pagination';
import HelparAlert from '../Helpars/Alert';
import DeleteConfirmationDialog from '../Helpars/DeleteConfirmationDialog';

import moment from 'moment';



const AccessesListPartial = (props) => {
    const params = useParams();
    const [accessesList, setAccessesList] = useState([]);
    const [statusList, setStatusList] = useState('');
    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [messageList, setMessageList] = useState('');
    const [errors, setErrors] = useState('');

    const [searchQuery, setSearchQuery] = useState('');
    const [searchQueryDateFrom, setSearchQueryDateFrom] = useState('');
    const [searchQueryDateTo, setSearchQueryDateTo] = useState('');

    const [isPageLoading, setPageLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1); // Current page starts at 1
    const [pageCount, setPageCount] = useState(null); // Current page starts at 1
    const [recordsPerPage, setRecordsPerPage] = useState(null); // Current page starts at 1
    const itemsPerPage = 20; // Number of items to display per page

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [accessId, setAccessId] = useState(false);
    const [isDeleteLoading, setDeleteLoading] = useState(false);

    const handleDeleteClick = (id) => {
        setIsDeleteDialogOpen(true);
        setAccessId(id);
    };

    const handleConfirmDelete = async (id) => {
        setDeleteLoading(true)
        let response = await apiAccesses.Delete(id, params.hash_id)
        setStatus(response.status)
        setMessage(response.message)
        setErrors(response.errors)
        let pagecurrent = currentPage;
        if (pageCount > 1) {
            if (recordsPerPage === 1) {
                pagecurrent = currentPage - 1;
            }
        }
        handlePageChange(pagecurrent);
        setIsDeleteDialogOpen(false);
        setDeleteLoading(false)
    };

    const handleCloseDialog = () => {
        setIsDeleteDialogOpen(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        ListAccesses(page);
    };
    const onChangeQuery = (e) => {
        setSearchQuery(e.target.value);
    };
    const onChangeQueryDateFrom = (e) => {
        ;
        setSearchQueryDateFrom(e.target.value);
    };
    const onChangeQueryDateTo = (e) => {
        setSearchQueryDateTo(e.target.value);
    };
    useEffect(() => {
        ListAccesses(1);
    }, [])
    const ListAccesses = async (page ) => {

        let response = await apiAccesses.list(params.hash_id, searchQuery, searchQueryDateFrom, searchQueryDateTo, props.reader_id,
            props.acceess_module_id, props.form_id, page, itemsPerPage);
        setAccessesList(response.records)
        setStatusList(response.status)
        setMessageList(response.message)
        setPageCount(Math.ceil(response.total / itemsPerPage))
        setRecordsPerPage(response.records_per_page);
        setPageLoading(false);
    }
    const handleSearch = () => {
        ListAccesses(1);
    };
    function DateFormat(date) {
        return (
            <>
                {format(parseISO(date), 'MMMM d, yyyy')}
            </>
        )
    }

    return (
        <>
            <Row>

                <Col md={2} className="mb-3">

                    <Form.Control
                        placeholder="Access Name"
                        aria-label="Access Name"
                        aria-describedby="Access Name"
                        value={searchQuery}
                        onChange={onChangeQuery}
                        type="search"
                    />
                </Col>

                <Col md={3} className="mb-3">
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3">
                            From
                        </InputGroup.Text>
                        <Form.Control placeholder="Logged from"
                            aria-label="Logged from"
                            aria-describedby="Logged from"
                            value={searchQueryDateFrom}
                            onChange={onChangeQueryDateFrom}
                            type="datetime-local"   />
                    </InputGroup>

                </Col>
                <Col md={3} className="mb-3">
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3">
                            To
                        </InputGroup.Text>
                        <Form.Control placeholder="Logged from"
                            aria-label="Logged from"
                            aria-describedby="Logged from"
                            value={searchQueryDateTo}
                            onChange={onChangeQueryDateTo}
                            type="datetime-local" />
                    </InputGroup>
                </Col>
                <Col md={2} className="mb-3">
                    <Button variant="primary" id="button-addon2" onClick={handleSearch}>
                        Search
                    </Button>
                </Col>
            </Row>
            {isPageLoading ? (
                <PageLoading />
            ) : (
                < >
                    {statusList === "success" ?
                        < >
                            <HelparAlert status={status} message={message} errors={errors} ></HelparAlert>
                            <Table responsive="sm" striped="columns" >
                                <thead>
                                    <tr>
                                        <th>User</th>
                                        <th>Access Name</th>
                                        <th>Starts at</th>
                                        <th>Ends at</th>
                                        <th>Status</th>
                                        <th>Access Module</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {accessesList.map(item => (
                                        <tr key={item.id}>
                                            <td className="d-flex">{item.user && item.user.avatar_url !== null ?
                                                <Image src={item.user.avatar_url} width={30}
                                                    height={30} rounded /> :
                                                <div style={{ width: 30, height: 30 }} className="d-flex bg-primary border-primary fs-6 text-white fw-bold  align-content-center align-items-center justify-content-center rounded mb-3">{item.user && item.user.first_name && item.user.first_name[0].toUpperCase()} {item.user && item.user.last_name && item.user.last_name[0].toUpperCase()}</div>
                                            }
                                                <span className="ms-1">{item.user.first_name} {item.user.last_name}</span></td>
                                            <td>{item.name}</td>
                                            {item.expiration_type === "date" ? <><td>{DateFormat(item.starts_at)}</td><td> {DateFormat(item.ends_at)}</td></> :
                                                <td colSpan={2}> Forever </td>
                                            }
                                            <td>{item.expiration_type === "date" ? new Date(item.ends_at) <= new Date() ? <Badge bg="danger">Date Expired</Badge> : item.checkins_maximum !== null && item.checkins_maximum <= item.checkins_count ? <Badge bg="danger">Check-ins Expired</Badge> : <Badge bg="success">Date Exist</Badge> : <Badge bg="success">Date Exist</Badge>}</td>
                                            <td>{item.access_module_title}</td>
                                            <td> <Button variant="danger" size='sm' onClick={() => handleDeleteClick(item.id)}>Delete</Button> </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <DeleteConfirmationDialog
                                id={accessId}
                                isLoading={isDeleteLoading}
                                isOpen={isDeleteDialogOpen}
                                onDelete={handleConfirmDelete}
                                onClose={handleCloseDialog}
                            />
                            {/* Render the pagination component */}
                            <HelparPagination
                                pageCount={pageCount}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                            />
                        </ >
                        : <Alert color="secondary" className="text-center">
                            <h5 className="mb-3">{messageList}</h5>  <Link to={"/business/projects/accesses/create/" + params.hash_id} className="btn btn-sm btn-light">Create New Access</Link>
                        </Alert>
                    }
                </ >
            )}
        </>
    );
}
export default AccessesListPartial;