/* eslint-disable import/no-anonymous-default-export */
import authService from '../api-authorization/AuthorizeService';
import ApiBase from './Base'

const url = "api/access_module_forms/"

export default {
    async list(hash_id, access_model_id, search_text = '', page, per) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const response = await ApiBase.apiBase(url + 'list/' + access_model_id + '/' + hash_id + '?search_text=' + search_text + '&page=' + page + '&per=' + per, token, 'GET')
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async GetById(hash_id, id) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const response = await ApiBase.apiBase(url + 'get_by_id/' + id + '/' + hash_id, token, 'GET')
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async GetByHashId(hash_id) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const response = await ApiBase.apiBase(url + 'get_by_hash_id/' + hash_id, token, 'GET')
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Create(accessModuleId, hash_id, body) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const urls = url + 'create/' + accessModuleId + '/' + hash_id;
            const response = await ApiBase.apiBase(urls, token, 'POST', body, true)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Update(id, hash_id, body) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const urls = url + 'update/' + id + "/" + hash_id;
            const response = await ApiBase.apiBase(urls, token, 'PUT', body, true)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Delete(id, hash_id) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const urls = url + 'delete/' + id + '/' + hash_id;
            const response = await ApiBase.apiBase(urls, token, 'DELETE')
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
}
