import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PageLoading from './Helpars/PageLoading';
import { Layout } from './Layouts/Layout';
import apiTermsAndConditions from './Apis/TermsAndConditions';
import HelparAlert from './Helpars/Alert';


const TermsAndConditions = () => {
    const params = useParams();
    const [termsAndConditions, setTermsAndConditions] = useState(null);
    const [isPageLoading, setPageLoading] = useState(true);
    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState('');

    useEffect(() => {
        GetTermsAndConditions();
    }, [])

    const GetTermsAndConditions = async () => {
        setPageLoading(true);
        let response = await apiTermsAndConditions.GetByType(params.type);
        setTermsAndConditions(response.data)
        setStatus(response.status)
        setMessage(response.message)
        setErrors(response.errors)

        setPageLoading(false);
    }
    return (
        <Layout rootName="Readers" buttonUrl={"/business/projects/reader-app/create/" + params.hash_id} buttonName="Create New Reader" >
            {isPageLoading ? (
                <PageLoading />
            ) : (

                < >

                    <HelparAlert status={status} message={message} errors={errors} ></HelparAlert>
                    <section>
                            <h2>{termsAndConditions.title }</h2>
                            <div dangerouslySetInnerHTML={{ __html: termsAndConditions.content }}/>
                    </section>
                </ >
            )}
        </Layout>
    )
}
export default TermsAndConditions;