import React from "react";
import { Link } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';

const LandingPrice = () => {
    return (
        <section id="Price" class="pt-4 pt-md-11 mt-4   text-center py-5" >
            <div class="container  p-5">
                <h1 className="fs-2 fw-bold  mb-5">
                    Customized Pricing for You
                </h1>
                <p class="lead mb-6 mb-lg-8 fs-4">Recognizing that one size doesn't fit all,
                    KNTO Pass offers personalized plans tailored to your unique requirements. </p>
                <p class="lead text-center mb-6 mb-lg-8 fs-4">
                    Share insights about your company, and let us craft a plan that aligns perfectly with your needs.
                </p>
                <div class="text-center">
                    <Link to={"/business/request"} class="btn btn-primary text-uppercase p-3 lift me-1">
                        Request to Create Your Business <Icon.ArrowRight size={25} /> 
                    </Link>
                </div>
            </div>
        </section>
    );
};
export default LandingPrice;
