import React from "react";
  
const LandingOurSolutions = () => {
    return (
        <section id="OurSolutions" class="text-center" style={{ backgroundColor: '#eee' }}>
            <div class="container p-5">
                <div class="align-items-center">
                    <h1 className="fs-2 fw-bold mb-5">
                        Our Solutions
                    </h1>
                    <p class="lead  text-muted mb-6 mb-lg-8 fs-4" style={{ color: '#6e6e6e' }}>
                        Unlock the potential of modern identity management with our tailored solutions designed to cater to diverse industries.
                        <br></br>We seamlessly blend physical and logical controls for a comprehensive approach.
                    </p>
                </div>
            </div>
        </section>
    );
};
export default LandingOurSolutions;
