/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
 import LandingFooter from './Footer';

import Header from './header';
import LandingAbout from './About';
import LandingOurSolutions from './OurSolutions';
import LandingKeyFeatures from './KeyFeatures';
import LandingBusinessDashboard from './BusinessDashboard';
import LandingReaderApp from './ReaderApp';
import LandingMobileApp from './MobileApp';
import LandingPrice from './Price';

const LandingIndex = () => {
    useEffect(() => {
        const root = document.documentElement;
        root.style.removeProperty('--bs-primary');

    }, [])
    return (
        <div>
            <Header />
            {/* ----Body---- */}
            <div
                data-bs-spy="scroll"
                data-bs-target="#navbar-example2"
                data-bs-root-margin="0px 0px -40%"
                data-bs-smooth-scroll="true"
                class="scrollspy-example bg-body-tertiary rounded-2 "
                tabindex="0" >
                <LandingAbout/>
                <LandingOurSolutions />
                <LandingKeyFeatures />
                <LandingBusinessDashboard />
                <LandingReaderApp />
                <LandingMobileApp /> 
                <LandingPrice /> 
            </div>
            <LandingFooter />
        </div>
    );
};
export default LandingIndex;