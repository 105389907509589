/* eslint-disable import/no-anonymous-default-export */
import authService from '../api-authorization/AuthorizeService';
import ApiBase from './Base'

const url = "api/accesses/invitations/"

export default {
    async list(hash_id, access_parent_id, page, per) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const response = await ApiBase.apiBase(url + 'list/' + hash_id + '?access_parent_id=' + access_parent_id + 'page=' + page + '&per=' + per, token, 'GET')
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async GetByAccessHashId(access_hash_id) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const response = await ApiBase.apiBase(url + 'get_by_hash_id/' + access_hash_id , token, 'GET')
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Delete(id, hash_id) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const urls = url + 'delete/' + id + '/' + hash_id;
            const response = await ApiBase.apiBase(urls, token, 'DELETE')
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    }
}
