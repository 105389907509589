import React from 'react';
import { LayoutProjects } from '../Layouts/LayoutProjects';
import { useParams } from 'react-router-dom';
import AccessesPartialList from '../Accesses/ListPartial'
const FormsAccesses = () => {
    const params = useParams();
    return (

        <LayoutProjects rootName="Access Module Form" rootLink={"/business/projects/forms/list/" + params.hash_id} currentRootName={params.name + " > Accesses"}  >
            <AccessesPartialList form_id={params.id} />
        </LayoutProjects>
    );
}
export default FormsAccesses;