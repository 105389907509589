import React from "react";
import { Link } from 'react-router-dom';
import { Navbar, Container, Nav, Image } from "react-bootstrap";
import logo from "../assets/img/logo.svg"; // Replace with the actual path to your SVG file
import { LoginMenu } from "../api-authorization/LoginMenu";

const Header = () => {
    return (
        <Navbar fixed="top" bg="light" data-bs-theme="light" expand="lg" id="navbar-example">
            <Container>
                <Navbar.Brand href="#home">
                    <Image src={logo} className="logo" width={200} />{" "}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto fs-6 fw-bold ms-3">
                        <Nav.Link href="#Home">Home</Nav.Link>
                        <Nav.Link href="#OurSolutions">Our Solutions</Nav.Link>
                        <Nav.Link href="#KeyFeatures">Key Features</Nav.Link>
                        <Nav.Link href="#BusinessDashboard">Business Dashboard</Nav.Link>
                        <Nav.Link href="#ReaderApp">Reader App</Nav.Link>
                        <Nav.Link href="#MobileApp">Mobile App</Nav.Link>
                        <Nav.Link href="#Price">Price</Nav.Link>
                    </Nav>
                    <Nav className="align-content-center align-items-center justify-content-center">
                        <LoginMenu />
                        <Link to="/business/projects/list" className="text-decoration-none ms-2 fw-normal" >Your Business</Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};
export default Header;
