import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Row, Col, Alert, Button, Dropdown, InputGroup, Form } from 'react-bootstrap';
import apiAccessModules from '../../Apis/AccessModules';
import * as Icon from 'react-bootstrap-icons';
import PageLoading from '../../Helpars/PageLoading';
import HelparPagination from '../../Helpars/Pagination';
import DeleteConfirmationDialog from '../../Helpars/DeleteConfirmationDialog';
import HelparAlert from '../../Helpars/Alert';





const ModeratorAccessModulesList = () => {
    const params = useParams();
    const [accessModulesList, setAccessModulesist] = useState([]);
    const [status, setStatus] = useState('');
    const [statusList, setStatusList] = useState('');
    const [message, setMessage] = useState('');
    const [messageList, setMessageList] = useState('');
    const [errors, setErrors] = useState(''); const [isPageLoading, setPageLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');


    const [currentPage, setCurrentPage] = useState(1); // Current page starts at 1
    const [pageCount, setPageCount] = useState(null); // Current page starts at 1
    const [recordsPerPage, setRecordsPerPage] = useState(null);
    const itemsPerPage = 20; // Number of items to display per page

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [accessId, setAccessId] = useState(false);
    const [isDeleteLoading, setDeleteLoading] = useState(false);

    const handleDeleteClick = (id) => {
        setIsDeleteDialogOpen(true);
        setAccessId(id);
    };

    const handleConfirmDelete = async (id) => {
        setDeleteLoading(true)
        let response = await apiAccessModules.Delete(id, params.hash_id)
        setStatus(response.status)
        setMessage(response.message)
        setErrors(response.errors)
        let pagecurrent = currentPage;
        if (pageCount > 1) {
            if (recordsPerPage === 1) {
                pagecurrent = currentPage - 1;
            }
        }
        handlePageChange(pagecurrent);
        setIsDeleteDialogOpen(false);
        setDeleteLoading(false)
    };

    const handleCloseDialog = () => {
        setIsDeleteDialogOpen(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        ListAccessModules(page);
    };
    const onChangeQuery = (e) => {
        setSearchQuery(e.target.value);
        if (e.target.value === '') {
            ListAccessModules(1);
        }
    };
    const handleSearch = () => {
        ListAccessModules(1, searchQuery);
    };
    useEffect(() => {
        ListAccessModules();
    }, [])
    const ListAccessModules = async (page, searchQueryData) => {
        setPageLoading(true);
        let response = await apiAccessModules.moderatorList(searchQueryData, page, itemsPerPage);
        setAccessModulesist(response.records)
        setStatusList(response.status)
        setMessageList(response.message)
        setPageCount(Math.ceil(response.total / itemsPerPage))
        setRecordsPerPage(response.records_per_page);
        setPageLoading(false);
    }
    return (
        < >
            <Link to='/moderator/access-modules/create' className="btn btn-sm btn-primary">Create</Link>
            <Row>
                <Col md={4} className="mb-3">
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder="Title"
                            aria-label="Title"
                            aria-describedby="Title"
                            value={searchQuery}
                            onChange={onChangeQuery}
                            type="search"
                        />
                        <Button variant="primary" id="button-addon2" onClick={handleSearch}>
                            Search
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
            {isPageLoading ? (
                <PageLoading />
            ) : (

                < >
                    {statusList === "success" ?
                        < >

                            <HelparAlert status={status} message={message} errors={errors} ></HelparAlert>
                            <Row>
                                {accessModulesList.map(item => (
                                    <Col md={4} className="mb-3">
                                        <Card key={item.id} className="shadow-sm" border="drck">
                                            <Card.Body className="d-flex align-items-center flex-column py-9 px-5">
                                                <div style={{ width: 60, height: 60 }} className="d-flex bg-primary border-primary fs-5 text-white fw-bold rounded align-content-center align-items-center justify-content-center me-2 mb-3" > <Icon.LayersFill size={40} /></div>
                                                <Card.Title className="fs-5 text-gray-800 text-hover-primary mb-3 ">{item.title.charAt(0).toUpperCase() + item.title.slice(1)}</Card.Title>
                                                <div className="d-flex">
                                                    <div className="d-flex align-start">
                                                        <Link to={"/moderator/access-modules/assign/" + item.id } className="btn btn-sm btn-primary me-1">Assign to business</Link>
                                                        <Link to={"/moderator/access-modules-packages/create/" + item.id} className="btn btn-sm btn-primary me-1">  Prices</Link>
                                                    </div>
                           
                                                </div>

                                            </Card.Body>
                                        </Card>
                                    </Col>

                                ))}
                            </Row>
                            <DeleteConfirmationDialog
                                id={accessId}
                                isLoading={isDeleteLoading}
                                isOpen={isDeleteDialogOpen}
                                onDelete={handleConfirmDelete}
                                onClose={handleCloseDialog}
                            />
                            {/* Render the pagination component */}
                            <HelparPagination
                                pageCount={pageCount}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                            />
                        </ >
                        : <Alert color="secondary" className="text-center">
                            <h5 className="mb-3">{messageList}</h5>  <Link to={"/business/projects/access-modules/create/" + params.hash_id} className="btn btn-sm btn-light">Create New Access Module</Link>
                        </Alert>
                    }
                </ >
            )}
        </ >
    );
}
export default ModeratorAccessModulesList;