/* eslint-disable react-hooks/exhaustive-deps */
import React  from 'react';
import { Form  } from 'react-bootstrap';

const CustomFieldsIndex = ({ custom_fields, setCustomFieldData }) => {

    const handleTextBoxValueChange = (index, value) => {
        const updatedTextBoxes = [...custom_fields];
        updatedTextBoxes[index].value = value;
        setCustomFieldData(updatedTextBoxes);
    };
    const handleDropdownChange = (index, value) => {
        const updatedCustomField = [...custom_fields];
        updatedCustomField[index].value = value;
        setCustomFieldData(updatedCustomField);
    };
    return (
        <>
            {custom_fields && custom_fields.map((item, index) => (
                item.type === "input" ?
                    <Form.Group className="mb-3">
                        <Form.Label>{item.label}</Form.Label>
                        {item.type === "textarea" ?
                            <Form.Control as="textarea" value={item.value} placeholder={"Enter " + item.label} required={item.is_required} onChange={(e) => handleTextBoxValueChange(index, e.target.value)} />
                            :
                            <Form.Control type={item.data_type} value={item.value} placeholder={"Enter " + item.label} required={item.is_required} onChange={(e) => handleTextBoxValueChange(index, e.target.value)} />
                        }
                        <Form.Control.Feedback type="invalid">
                            {item.label} is required
                        </Form.Control.Feedback>
                    </Form.Group> :

                    item.type === "dropdown" ?
                        <Form.Group className="mb-3">
                            <Form.Label>{item.label}</Form.Label>
                            <Form.Select value={item.value} className="mb-3" required={item.is_required} onChange={(e) => handleDropdownChange(index, e.target.value)}>
                                <option value=''>Select {item.label}</option>
                            {item.options && item.options.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                        </option>
                                    ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {item.label} is required
                            </Form.Control.Feedback>
                        </Form.Group>
                         :
                        <></>
            ))}
        </>
    );
}
export default CustomFieldsIndex;


