/* eslint-disable import/no-anonymous-default-export */

export default {
    async apiBase(url, token, methodType, bodyObj, isFormData = false) {
        let response = await fetch(url, {
            method: methodType,
            headers: isFormData ? {
                'Authorization': token && `Bearer ${token}`,

            } : {
                'Authorization': token && `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: bodyObj ? isFormData ? bodyObj : JSON.stringify(bodyObj) : null
        });
        let responseJson = await response.json();
        return responseJson;
    }
}