/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Image, ProgressBar, Dropdown, Alert, Button, Badge } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import ApiProjects from '../Apis/Projects';
import * as Icon from 'react-bootstrap-icons';
import PageLoading from '../Helpars/PageLoading';
import apiAccessLogs from '../Apis/AccessLogs';
import { LayoutProjects } from '../Layouts/LayoutProjects';
import moment from 'moment';
import apiAccessModules from '../Apis/AccessModules';


const BusinessIndex = () => {
    const params = useParams();
    const [projectData, setProjectData] = useState(null);
    const [projectStatistics, setProjectStatistics] = useState(null);
    const [isPageLoading, setPageLoading] = useState(true);
    const [accessLogsList, setAccessLogsList] = useState([]);
    const [accessLogsStatus, setAccessLogsStatus] = useState('');
    const [accessLogsMessage, setAccessLogsMessage] = useState('');
    const [accessLogsTotal, setAccessLogsMessageTotal] = useState(0);
    const [accessModulesList, setAccessModulesist] = useState([]);
    const [accessModuleStatus, setAccessModuleStatus] = useState('');
    const [accessModuleMessage, setAccessModuleMessage] = useState('');
    const [accessModuleTotal, setAccessLogsModuleTotal] = useState(0);

    useEffect(() => {
        populateProjectData();
        ListAccessLogs();
        ListAccessModules();
        GetProjectStatistics();

    }, [params.hash_id])


    const populateProjectData = async () => {
        let response = await ApiProjects.GetById(params.hash_id);
        setProjectData(response.data)
        setPageLoading(false);
    }

    const calculateDateDifference = (date) => {
        const endDate = new Date(date);
        const startDate = new Date();
        const DateDifference = (startDate - endDate) / 1000;

        if (DateDifference < 60) {
            return (<>{parseInt(DateDifference) + ' sec ago'}</>);
        }
        else if (DateDifference < 60 * 60) {
            return (<>{parseInt(DateDifference / (60)) + ' min ago'}</>);
        }
        else if (DateDifference < 60 * 60 * 24) {
            return (<>{parseInt(DateDifference / (60 * 60)) + ' hr ago'}</>);
        }
        else if (DateDifference < 60 * 60 * 24 * 30) {
            return (<>{parseInt(DateDifference / (60 * 60 * 24)) + ' day ago'}</>);
        }
        else if (DateDifference < 60 * 60 * 24 * 30 * 12) {
            const months = (startDate.getFullYear() - endDate.getFullYear()) * 12 +
                (startDate.getMonth() - endDate.getMonth());
            return (<>{months + ' month ago'}</>);
        }
        else if (DateDifference > 60 * 60 * 24 * 30 * 12) {
            return (<>{startDate.getFullYear() - endDate.getFullYear() + ' yr ago'}</>);
        }
    };

    const ListAccessLogs = async () => {
        let response = await apiAccessLogs.list(params.hash_id, '', '', '', '', '',
            '', '', 1, 50);
        setAccessLogsList(response.records)
        setAccessLogsMessageTotal(response.total)
        setAccessLogsStatus(response.status)
        setAccessLogsMessage(response.message)
    }
    const ListAccessModules = async () => {
        let response = await apiAccessModules.list(params.hash_id, '', 1, 2);
        setAccessModulesist(response.records);
        setAccessLogsModuleTotal(response.total)
        setAccessModuleStatus(response.status);
        setAccessModuleMessage(response.message);
    }
    const GetProjectStatistics = async () => {
        let response = await ApiProjects.GetStatistics(params.hash_id);
        setProjectStatistics(response.data);

    }
    const subscriptionDatestatus = (start, end) => {
        const currentDate = new Date();
        if (new Date(start) > currentDate) {
            return (
                <Badge bg="secondary">not start</Badge>
            );
        }
        else if (new Date(end) < currentDate) {
            return (
                <Badge bg="danger">Expired</Badge>
            );
        }
        else if (new Date(start) <= currentDate && new Date(end) >= currentDate) {
            return (
                <Badge bg="success">Existing</Badge>
            );
        }
    }
    return (
        <LayoutProjects  >
            {isPageLoading ? (
                <PageLoading />
            ) : (
                // Render your content when loading is finished
                <Card className="shadow-sm">
                    <Card.Body>
                        {projectData !== null ?
                            <div className="d-flex justify-content-between align-items-start flex-wrap">
                                <div className="d-flex ">
                                    {projectData.logo_url !== null ?
                                        <Image src={projectData.logo_url} width={100}
                                            height={100} rounded /> :
                                        <div className="d-flex bg-primary border-primary fs-2 text-white fw-bold  align-content-center align-items-center justify-content-center rounded project-index-logo  me-2 mb-3">{projectData.name[0].toUpperCase()}</div>
                                    }
                                    <div className="ms-2">
                                        <Card.Text className="text-hover-primary fs-4 fw-normal mb-0 pb-0 ">{projectData.name}</Card.Text>
                                        <div className=" ">
                                            {projectData.email !== null && projectData.email !== '' && <Card.Text className=" text-hover-primary fw-normal text-hover-primary mb-1 me-2"> <Icon.EnvelopeAt size={15} /> {projectData.email} </Card.Text>}
                                            {projectData.phone !== null && projectData.phone !== '' && <Card.Text className=" text-hover-primary fw-normal text-hover-primary m-0 "> <Icon.Phone size={15} /> {projectData.phone} </Card.Text>}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Link to={"/business/projects/update/" + projectData.id} className="btn btn-sm btn-primary"  >Edit</Link>
                                </div>
                            </div> : ''
                        }
                    </Card.Body>
                </Card>
            )}
            <Row className="mt-5">
                <Col md={8}>
                    <Row  >
                        <Col md={12}>
                            <Card className="shadow-sm">
                                <Card.Header className="d-flex justify-content-between align-items-start flex-wrap">
                                    <div>Access Modules <span className="fw-bold">({accessModuleTotal})</span></div>
                                    <div className="text-end">
                                        <Button variant="secondary" size="sm" className="me-3" onClick={() => ListAccessModules()}>
                                            <Icon.ArrowRepeat size={20} className="text-withe" />
                                        </Button>
                                    </div></Card.Header>
                                <Card.Body>
                                    {accessModuleStatus === "success" ?
                                        <Row>
                                            {accessModulesList.map(item => (
                                                <Col md={6} className="mb-3">
                                                    <Card key={item.id} className="shadow-sm" border="drck">
                                                        <Card.Body>
                                                            <div className="d-flex justify-content-between align-items-start flex-wrap">
                                                                <div className="d-flex ">
                                                                    <div style={{ width: 60, height: 60 }} className="d-flex bg-primary border-primary fs-5 text-white fw-bold rounded align-content-center align-items-center justify-content-center me-2 mb-3" > <Icon.LayersFill size={40} /></div>
                                                                    <Card.Title className="fs-5 text-gray-800 text-hover-primary mb-3 ">{item.title.charAt(0).toUpperCase() + item.title.slice(1)}</Card.Title>
                                                                </div>
                                                                <div className="ms-2">
                                                                    {item.business_access_module_quotas ?
                                                                        <>
                                                                            <div class="hstack gap-3 pb-2">
                                                                                <div style={{ fontSize: 12 }}>Start <span className='fw-bold text-primary'>{moment(item.business_access_module_quotas.starts_at).format('DD-MM-YYYY')}</span></div>
                                                                                <div style={{ fontSize: 12 }}>End <span className='fw-bold text-primary'>{moment(item.business_access_module_quotas.ends_at).format('DD-MM-YYYY')}</span></div>
                                                                                <div style={{ fontSize: 12 }}>{subscriptionDatestatus(item.business_access_module_quotas.starts_at, item.business_access_module_quotas.ends_at)}</div>
                                                                            </div>
                                                                            <div class="hstack gap-3">
                                                                                <div class="p-2 border"><div className='fs-6 fw-bold text-primary'>{item.business_access_module_quotas.user_maximum}</div> Users</div>
                                                                                <div class="p-2 border"><div className='fs-6 fw-bold text-primary'>{item.business_access_module_quotas.user_count ? item.business_access_module_quotas.user_count : 0}</div> Use</div>
                                                                                <div class="p-2 border"><div className='fs-6 fw-bold text-primary'>{item.business_access_module_quotas.user_maximum ? item.business_access_module_quotas.user_maximum - item.business_access_module_quotas.user_count : 0}</div> Remainder</div>
                                                                            </div>
                                                                            <ProgressBar now={item.business_access_module_quotas.user_count / item.business_access_module_quotas.user_maximum * 100} label={`${parseInt(item.business_access_module_quotas.user_count / item.business_access_module_quotas.user_maximum * 100)}%`} className="mt-3" />
                                                                        </>
                                                                        : <div class="hstack gap-3">
                                                                            <div class="p-2 border"><div className='fs-6 fw-bold text-primary'>Unlimited</div> Users</div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div>

                                                                <div class="row my-3" style={{ fontSize: 14 }}>
                                                                    <div class="col-md-4 mb-2"><Icon.Calendar2RangeFill size={15} className="me-2" />Date: {item.duration_type}</div>

                                                                    {item.access_module_features && item.access_module_features.is_feature_checkins &&
                                                                        <div class="col-md-4 mb-2"><Icon.UiChecksGrid size={15} className="me-2" />{item.checkins_maximum} checkins</div>
                                                                    }
                                                                    {item.access_module_features && item.access_module_features.is_feature_invitions &&
                                                                        <div class="col-md-4 mb-2"><Icon.EnvelopePlusFill size={15} className="me-2" />{item.invitation_maximum ? item.invitation_maximum : 'Unlimited'} Invitations</div>
                                                                    }
                                                                    {item.access_module_features && item.access_module_features.is_feature_form &&
                                                                        <div class="col-md-4 mb-2"><Icon.List size={15} className="me-2" />{item.form_maximum ? item.form_maximum : 'Unlimited'} Forms</div>
                                                                    }
                                                                    {item.access_module_features && item.access_module_features.is_feature_reader_app &&
                                                                        <div class="col-md-4 mb-2"><Icon.PhoneFill size={15} className="me-2" />{item.reader_app_maximum ? item.reader_app_maximum : 'Unlimited'} Readers</div>
                                                                    }
                                                                    {item.access_module_features && item.access_module_features.is_feature_send_access_by_csv &&
                                                                        <div class="col-md-4 mb-2"><Icon.FiletypeCsv size={15} className="me-2" />Send Access By CSV</div>
                                                                    }
                                                                    {item.business_access_module_quotas && item.business_access_module_quotas.access_maximum &&
                                                                        <div class="col-md-4 mb-2"><Icon.TicketFill size={15} className="me-2" />{item.business_access_module_quotas && item.business_access_module_quotas.access_maximum ? item.business_access_module_quotas.access_maximum : 'Unlimited'} Access</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="d-flex align-start">
                                                                    <Link to={"/business/projects/accesses/create/" + item.id + "/" + params.hash_id} className="btn btn-sm btn-primary me-1">Send Access</Link>
                                                                    <Link to={"/business/projects/forms/list/" + item.id + "/" + params.hash_id} className="btn btn-sm btn-primary me-1">  Forms</Link>
                                                                </div>
                                                                <div className="ms-1">

                                                                </div> <Dropdown >
                                                                    <Dropdown.Toggle variant="secondary" size='sm' id="dropdown-basic" className="btn-circle">
                                                                        <Icon.ThreeDots size={20} />
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Link to={"/business/projects/access-modules/accesses/" + item.id + "/" + item.title + "/" + params.hash_id} disabled className="dropdown-item">Accesses</Link>
                                                                        <Link to={"/business/projects/access-modules/access-logs/" + item.id + "/" + item.title + "/" + params.hash_id} className="dropdown-item">Access Logs</Link>
                                                                        <Link to={"/business/projects/access-modules/update/" + item.id + "/" + params.hash_id} disabled className="dropdown-item">Edit</Link>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>

                                                        </Card.Body>
                                                    </Card>
                                                </Col>

                                            ))}
                                        </Row>
                                        : <Alert color="secondary" className="text-center">
                                            <h5 className="mb-3">{accessModuleMessage}</h5>
                                        </Alert>
                                    }
                                    <div className="text-center mt-3">
                                        <Link to={"/business/projects/reader-app/list/" + params.hash_id} className="btn btn-sm btn-primary me-3"  >View all...</Link>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md={6}>
                            <Card className="shadow-sm text-center">
                                <Card.Header>Reader App</Card.Header>
                                <Card.Body>
                                    <div className="p-5">
                                        <div><span className="fs-2 fw-bold">{projectStatistics && projectStatistics.reader_app && projectStatistics.reader_app.total}</span></div>
                                    </div>
                                    <div >
                                        <Link to={"/business/projects/reader-app/list/" + params.hash_id} className="btn btn-sm btn-primary me-3"  >View</Link>
                                        <Link to={"/business/projects/reader-app/create/" + params.hash_id} className="btn btn-sm btn-primary"  >Create</Link>

                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className="shadow-sm text-center">
                                <Card.Header>Forms</Card.Header>
                                <Card.Body>
                                    <div className="p-5">
                                        <div><span className="fs-2 fw-bold">{projectStatistics && projectStatistics.forms && projectStatistics.forms.total}</span></div>
                                    </div>
                                    <div >
                                        <Link to={"/business/projects/forms/list/" + params.hash_id} className="btn btn-sm btn-primary me-3"  >View</Link>
                                        <Link to={"/business/projects/forms/create/" + params.hash_id} className="btn btn-sm btn-primary"  >Create</Link>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md={4}>
                            <Card className="shadow-sm">
                                <Card.Header>Accesses</Card.Header>
                                <Card.Body>
                                    <div className="row p-2">
                                        <div className="col-md-4 p-2">
                                            <div style={{ fontSize: 12 }}>All<div className="fs-6 fw-bold">{projectStatistics && projectStatistics.access && projectStatistics.access.total}</div></div>
                                        </div>
                                        <div className="col-md-4 p-2">
                                            <div style={{ fontSize: 12 }}>Exist<div className="fs-6 fw-bold">{projectStatistics && projectStatistics.access && projectStatistics.access.exist}</div></div>
                                        </div>
                                        <div className="col-md-4 p-2">
                                            <div style={{ fontSize: 12 }}>Expired<div className="fs-6 fw-bold">{projectStatistics && projectStatistics.access && projectStatistics.access.expired}</div></div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <Link to={"/business/projects/accesses/list/" + params.hash_id} className="btn btn-sm btn-primary me-3"  >View</Link>
                                        <Link to={"/business/projects/accesses/create/" + params.hash_id} className="btn btn-sm btn-primary"  >Create</Link>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="shadow-sm">
                                <Card.Header>Access Invitations</Card.Header>
                                <Card.Body>
                                    <div className="row p-2">
                                        <div className="col-md-4 p-2">
                                            <div style={{ fontSize: 12 }}>All<div className="fs-6 fw-bold">{projectStatistics && projectStatistics.access_invitations && projectStatistics.access_invitations.total}</div></div>
                                        </div>
                                        <div className="col-md-4 p-2">
                                            <div style={{ fontSize: 12 }}>Exist<div className="fs-6 fw-bold">{projectStatistics && projectStatistics.access_invitations && projectStatistics.access_invitations.exist}</div></div>
                                        </div>
                                        <div className="col-md-4 p-2">
                                            <div style={{ fontSize: 12 }}>Expired<div className="fs-6 fw-bold">{projectStatistics && projectStatistics.access_invitations && projectStatistics.access_invitations.expired}</div></div>
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <Link to={"/business/projects/accesses/invitations/list/" + params.hash_id} className="btn btn-sm btn-primary me-3"  >View</Link>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="shadow-sm">
                                <Card.Header>Access Requests</Card.Header>
                                <Card.Body>
                                    <div className="row p-2">
                                        <div className="col-md-4 p-2">
                                            <div style={{ fontSize: 12 }}>Pending<div className="fs-6 fw-bold">{projectStatistics && projectStatistics.access_requests && projectStatistics.access_requests.pending}</div></div>
                                        </div>
                                        <div className="col-md-4 p-2">
                                            <div style={{ fontSize: 12 }}>Accepted<div className="fs-6 fw-bold">{projectStatistics && projectStatistics.access_requests && projectStatistics.access_requests.accepted}</div></div>
                                        </div>
                                        <div className="col-md-4 p-2">
                                            <div style={{ fontSize: 12 }}>Rejected<div className="fs-6 fw-bold">{projectStatistics && projectStatistics.access_requests && projectStatistics.access_requests.rejected}</div></div>
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <Link to={"/business/projects/accesses/requests/list/" + params.hash_id} className="btn btn-sm btn-primary me-3"  >View</Link>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col md={4}>
                    <Card className="shadow-sm">
                        <Card.Header className="d-flex justify-content-between align-items-start flex-wrap">
                            <div>Access Logs <span className="fw-bold">({accessLogsTotal})</span></div>
                            <div className="text-end">
                                <Button variant="secondary" size="sm" className="me-3" onClick={() => ListAccessLogs()}>
                                    <Icon.ArrowRepeat size={20} className="text-withe" />
                                </Button>
                                <Link to={"/business/projects/access-logs/list/" + params.hash_id} className="btn btn-sm btn-primary me-3"  >View All</Link>
                            </div></Card.Header>
                        <Card.Body>
                            {accessLogsStatus === "success" ?
                                <>
                                    {accessLogsList.map(item => (
                                        <div className="mb-2 pb-1 border-bottom">
                                            <div style={{ fontSize: 12 }} className="text-muted">
                                                {item.status === "success" ?
                                                    <Icon.CheckCircleFill size={15} className="text-success me-1" /> :
                                                    <Icon.XCircleFill size={15} className="text-danger me-1" />
                                                }
                                                {calculateDateDifference(moment.unix(item.logged_at))}</div>
                                            <span> <span className="fw-bold">{item.access.name}</span><span className="ms-1"><Icon.PhoneFill size={15} />{item.reader_app.name}</span></span>
                                        </div>
                                    ))}
                                </> :
                                <Alert color="primary" className="text-center">
                                    <h5 className="mb-3">{accessLogsMessage}</h5>
                                </Alert>
                            }
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </LayoutProjects>


    );
}
export default BusinessIndex;