import React from 'react';


const EmailUnsubscribe = () => {

    return (
        <div className="text-center mt-5">
            <h1>Unsubscription</h1>
            <h5>Your email was unsubscribed!</h5>
        </div>

    );
}
export default EmailUnsubscribe;