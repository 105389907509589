/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Image, Badge, Button, OverlayTrigger, Form, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ApiProjects from '../Apis/Projects';
import Loading from '../Helpars/Loading';



const FormPreview = (props) => {
    const params = useParams();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
    }, [])



    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <Card style={{ fontSize: 13 }}>
                    {props.cover_url &&
                        <div className="bg-primary text-white m-2 rounded" >
                            <img src={props.cover_url} style={{ maxHeight: 200, width: '100%' }} />
                        </div>
                    }
                    <Container>

                        <Card.Header className="bg-white text-center border-0">
                                <h1 className="fs-4 text-primary">{props.title ? props.title : 'Title -----------------'}</h1>
                                <p className="m-0">{props.description ? props.description:'Description ----------------------------------------'}</p>
                        </Card.Header>
                        <Card.Body >
                            <div className="bg-primary text-white p-2 rounded mb-3 fs-6">Enter your information to obtain a pass</div>
                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control placeholder="Enter name" required />
                                <Form.Text className="text-muted">
                                    Enter a maximum of 20 characters
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control placeholder="Enter email" required />
                            </Form.Group>
                        </Card.Body>
                        <Card.Footer className="d-flex justify-content-between align-items-center bg-white text-white  m-2 ">
                            <div></div>
                            <Button type="submit"
                                variant="primary" size="md"
                            >
                                Submit
                            </Button>
                        </Card.Footer>
                    </Container>
                </Card>
            )
            }

        </>

    );
}
export default FormPreview;


