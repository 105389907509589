import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Card, Form, Button, Row, Col, Table } from 'react-bootstrap';
import apiAccessModules from '../../Apis/AccessModules';
import apiAccessModulePackages from '../../Apis/AccessModulePackages';
import HelparAlert from '../../Helpars/Alert';
import moment from 'moment';


const ModeratorAccessModuleAssign = () => {
    const params = useParams();
    const [isLoading, setLoading] = useState(false);

    const [projectHash, setProjectHash] = useState('');
    const [usersQouta, setUsersQouta] = useState('');

    const [packages, setPackages] = useState(null);
    const [packageId, setPackageId] = useState('');
    const [durationEndDate, setDurationEndDate] = useState('');


    const [accessModulePackagesList, setAccessModulePackagesList] = useState('');

    const [startsAt, setStartsAt] = useState('');
    const [endsAt, setEndsAt] = useState(null);

    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState('');
    const [validated, setValidated] = useState(false);

    const onChangeProjectHash = (event) => {
        setProjectHash(event.target.value);
    }
    const onChangeUsersQouta = (event) => {
        setUsersQouta(event.target.value);
    }
    const onChangeStartsAt = (event) => {
        setStartsAt(event.target.value);
        changeDateStart(packages.access_module_scheme, event.target.value, packages.duration_type, packages.duration_number)
    }
    const changeDateStart = (access_module_scheme, star_at, duration_type, duration_number) => {

        if (access_module_scheme === "duration") {
            if (star_at) {
                const currentDate = moment(star_at);
                if (duration_type && duration_type === "day") {
                    setDurationEndDate(currentDate.add(duration_number ? duration_number : 1, 'day').format('DD-MM-YYYY'));
                }
                else if (duration_type && duration_type === "month") {
                    setDurationEndDate(currentDate.add(duration_number ? duration_number : 1, 'months').format('DD-MM-YYYY'));
                }
                else if (duration_type && duration_type === "week") {
                    setDurationEndDate(currentDate.add(duration_number ? duration_number : 1, 'week').format('DD-MM-YYYY'));
                }
                else if (duration_type && duration_type === "year") {
                    setDurationEndDate(currentDate.add(duration_number ? duration_number : 1, 'year').format('DD-MM-YYYY'));
                }
            }
            else {
                setDurationEndDate('')
            }
        }
    }
    const onChangeEndsAt = (event) => {
        setEndsAt(event.target.value);
    }

    useEffect(() => {
        ListAccessModulePackages();

    }, [])

    function handleRidoPackagesChange(event) {
        setPackageId(event.target.value);
        const pak = accessModulePackagesList.find(item => item.id === parseInt(event.target.value))
        setPackages(pak)
        if (startsAt) {
            changeDateStart(pak.access_module_scheme, startsAt, pak.duration_type, pak.duration_number)
        }
    }
    const ListAccessModulePackages = async () => {
        let response = await apiAccessModulePackages.List(params.access_module_id);
        setAccessModulePackagesList(response.records)
        setStatus(response.status)
        setMessage(response.message)
    }
    const onSubmit = async (event) => {
        setLoading(true);
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            let AccessModuleObj = {
                project_hash: projectHash,
                user_maximum: usersQouta,
                starts_at: startsAt,
                ends_at: endsAt,
                access_module_package_id: packageId,
            }
            let response = await apiAccessModules.Assign(params.access_module_id, AccessModuleObj)
            setStatus(response.status)
            setMessage(response.message)
            setErrors(response.errors)

            if (response.status === "success") {
                ListAccessModulePackages();
            }
        }
        setValidated(true);
        setLoading(false);
    }

    return (
        <div>
            <Container fluid="md">
                <Card className="shadow-sm p-3 mb-5 rounded border border-0">
                    <Card.Body>
                        <div>
                            <Form noValidate validated={validated} onSubmit={onSubmit} className="w-100" >
                                <h1 className="mb-5">Create Access Module Packages</h1>
                                <HelparAlert status={status} message={message} errors={errors} ></HelparAlert>

                                <Table responsive="sm" striped="columns" >
                                    <thead>
                                        <tr>
                                            <th> </th>
                                            <th>Scheme</th>
                                            <th>Title</th>
                                            <th>Price</th>
                                            <th>Duration</th>
                                            <th>Minimum Users</th>
                                            <th>Access Maximum</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {accessModulePackagesList && accessModulePackagesList.map((item) => (
                                            <tr key={item.id}>

                                                <td><Form.Check inline id={item.id} name="packageId" type="radio" value={item.id} onChange={handleRidoPackagesChange} required /></td>
                                                <td>{item.access_module_scheme}</td>
                                                <td>{item.title}</td>
                                                <td>{item.price}</td>
                                                <td>{item.duration_number + item.duration_type}</td>
                                                <td>{item.minimum_users}</td>
                                                <td>{item.access_maximum}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <Form.Group className="mb-3">
                                    <Form.Label>Project Id</Form.Label>
                                    <Form.Control value={projectHash} onChange={onChangeProjectHash} placeholder="Project Id" required />
                                    <Form.Control.Feedback type="invalid">
                                        Project Id is required
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Users Qouta</Form.Label>
                                    <Form.Control value={usersQouta} onChange={onChangeUsersQouta} placeholder="Users Qouta" required />
                                    <Form.Control.Feedback type="invalid">
                                        Users Qouta is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {packages &&
                                    <Form.Group className="mb-3">
                                        <Form.Label>Starts at</Form.Label>
                                        <Form.Control value={startsAt} onChange={onChangeStartsAt} type="date" required />
                                        <Form.Control.Feedback type="invalid">
                                            Starts at is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                }
                                {packages && packages.access_module_scheme === "one_use" &&

                                    <Form.Group className="mb-3">
                                        <Form.Label>Ends at</Form.Label>
                                        <Form.Control value={endsAt} onChange={onChangeEndsAt} type="date" required />
                                        <Form.Control.Feedback type="invalid">
                                            Ends at is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                }
                                {packages && packages.access_module_scheme === "duration" &&

                                    <Form.Group className="mb-3">
                                        <Form.Label>Ends at </Form.Label>
                                        <Form.Control value={durationEndDate} readonly />
                                        <Form.Control.Feedback type="invalid">
                                            Ends at is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                }

                                <Button type="submit"
                                    variant="primary" size="lg"
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Loading…' : 'submit'}
                                </Button>

                            </Form>
                        </div>



                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}
export default ModeratorAccessModuleAssign;