/* eslint-disable import/no-anonymous-default-export */
import authService from '../api-authorization/AuthorizeService';
import ApiBase from '../Apis/Base'

const url = "api/projects/"

export default {
    async Create(body) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const res = await ApiBase.apiBase(url + 'create', token, 'POST', body)
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Update(body) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const res = await ApiBase.apiBase(url + 'update', token, 'PUT', body, true)
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async list() {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const res = await ApiBase.apiBase(url + 'list', token, 'GET')
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },async GetById(id) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const res = await ApiBase.apiBase(url + 'get_by_id/' + id, token, 'GET')
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    }, async GetStatistics(id) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const res = await ApiBase.apiBase(url + 'get_statistics/' + id, token, 'GET')
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
}
