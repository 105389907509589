import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Profile from './Profile';
import ChangePassword from './ChangePassword';


const ManageAccount = () => {

    return (
        <Tabs
            defaultActiveKey="profile"
            id="fill-tab-example"
            className="mt-5"
            fill
        >
            <Tab eventKey="profile" title="Update Profile">
                <Profile />
            </Tab>
            <Tab eventKey="change-password" title="Change Password">
                <ChangePassword />
            </Tab>

        </Tabs>

    );
}
export default ManageAccount;