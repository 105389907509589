/* eslint-disable import/no-anonymous-default-export */
import authService from '../api-authorization/AuthorizeService';
import ApiBase from './Base'

const url = "api/business_categories/"

export default {
    async list() {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const response = await ApiBase.apiBase(url + 'list/', token, 'GET')
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },


}
