import React from 'react';
import { Pagination } from 'react-bootstrap';


const HelparPagination = ({ pageCount, currentPage, onPageChange }) => {
    const handlePageClick = (page) => {
        onPageChange(page);
    };
    return (
        <div className="d-flex mt-5  justify-content-center">
            {pageCount > 1 &&
                <Pagination>
                    <Pagination.First onClick={() => handlePageClick(1)} />
                    <Pagination.Prev onClick={() => handlePageClick(currentPage - 1)} />
                    {[...Array(pageCount).keys()].map((page) => (
                        <Pagination.Item
                            key={page + 1}
                            active={page + 1 === currentPage}
                            onClick={() => handlePageClick(page + 1)}
                        >
                            {page + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => handlePageClick(currentPage + 1)} />
                    <Pagination.Last onClick={() => handlePageClick(pageCount)} />
                </Pagination>
            }
        </div>
    );
}
export default HelparPagination;