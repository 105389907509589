import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Row, Col, Alert, Button, Dropdown, InputGroup, Form } from 'react-bootstrap';
import apiReaderApps from '../Apis/ReaderApps';
import PageLoading from '../Helpars/PageLoading';
import * as Icon from 'react-bootstrap-icons';
import { LayoutProjects } from '../Layouts/LayoutProjects';
import Pagination from '../Helpars/Pagination';
import DeleteConfirmationDialog from '../Helpars/DeleteConfirmationDialog';
import HelparAlert from '../Helpars/Alert';




const ReaderAppList = () => {
    const params = useParams();
    const [readersList, setReadersList] = useState([]);
    const [status, setStatus] = useState('');
    const [statusList, setStatusList] = useState('');
    const [message, setMessage] = useState('');
    const [messageList, setMessageList] = useState('');
    const [errors, setErrors] = useState('');
    const [isPageLoading, setPageLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');

    const [currentPage, setCurrentPage] = useState(1); // Current page starts at 1
    const [pageCount, setPageCount] = useState(null); // Current page starts at 1
    const [recordsPerPage, setRecordsPerPage] = useState(null);
    const itemsPerPage = 20; // Number of items to display per page


    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [accessId, setAccessId] = useState(false);
    const [isDeleteLoading, setDeleteLoading] = useState(false);

    const handleDeleteClick = (id) => {
        setIsDeleteDialogOpen(true);
        setAccessId(id);
    };
    const handleConfirmDelete = async (id) => {
        setDeleteLoading(true)
        let response = await apiReaderApps.Delete(id, params.hash_id)
        setStatus(response.status)
        setMessage(response.message)
        setErrors(response.errors)
        let pagecurrent = currentPage;
        if (pageCount > 1) {
            if (recordsPerPage === 1) {
                pagecurrent = currentPage - 1;
            }
        }
        handlePageChange(pagecurrent);
        setIsDeleteDialogOpen(false);
        setDeleteLoading(false)
    };

    const handleCloseDialog = () => {
        setIsDeleteDialogOpen(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        ListReaderApps(page, searchQuery);
    };
    const onChangeQuery = (e) => {
        setSearchQuery(e.target.value);
        if (e.target.value === '') {
            ListReaderApps(1, '');
        }
    };
    const handleSearch = () => {
        ListReaderApps(1, searchQuery);
    };
    useEffect(() => {
        ListReaderApps(1, '');
    }, [])
    const ListReaderApps = async (page, searchQueryData) => {
        setPageLoading(true);
        let response = await apiReaderApps.list(params.hash_id, searchQueryData, page, itemsPerPage);
        setReadersList(response.records)
        setStatusList(response.status)
        setMessageList(response.message)
        setPageCount(Math.ceil(response.total / itemsPerPage))
        setRecordsPerPage(response.records_per_page);
        setPageLoading(false);
    }
    return (
        <LayoutProjects rootName="Readers" buttonUrl={"/business/projects/reader-app/create/" + params.hash_id} buttonName="Create New Reader" >
            <Row>
                <Col md={4} className="mb-3">
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder="Name Or Username"
                            aria-label="Name Or Username"
                            aria-describedby="Name Or Username"
                            value={searchQuery}
                            onChange={onChangeQuery}
                            type="search"
                        />
                        <Button variant="primary" id="button-addon2" onClick={handleSearch}>
                            Search
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
            {isPageLoading ? (
                <PageLoading />
            ) : (

                < >
                    {statusList === "success" ?
                        < >
                            <HelparAlert status={status} message={message} errors={errors} ></HelparAlert>
                            <Row>
                                {readersList.map(item => (
                                    <Col md={4} className="mb-3">
                                        <Card key={item.id}>
                                            <Card.Body className="d-flex align-items-center flex-column py-9 px-5">
                                                <div style={{ width: 60, height: 60 }} className="d-flex bg-primary   fs-5 text-white fw-bold rounded align-content-center align-items-center justify-content-center me-2 mb-3" > <Icon.PhoneFill size={40} /></div>
                                                <Card.Title className="fs-5 text-gray-800 text-hover-primary  ">{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</Card.Title>
                                                <Card.Subtitle className="mb-2 text-muted  mb-3">{item.user_name}</Card.Subtitle>
                                                <div className="d-flex">
                                                    <div className="d-flex align-start">
                                                        <Link to={"/business/projects/reader-app/accesses/" + item.id + "/" + item.name + "/" + params.hash_id} className="btn btn-primary btn-sm ms-2">Accesses</Link>
                                                        <Link to={"/business/projects/reader-app/access_logs/" + item.id + "/" + item.name + "/" + params.hash_id} className="btn btn-primary btn-sm ms-2">Access Logs</Link>
                                                     </div>
                                                    <div className="ms-2">

                                                    </div> <Dropdown >
                                                        <Dropdown.Toggle variant="secondary" size='sm' id="dropdown-basic" className="btn-circle">
                                                            <Icon.ThreeDots size={20} />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Link to={"/business/projects/reader-app/update/" + item.id + "/" + params.hash_id} className="dropdown-item">Edit</Link>
                                                            <Link to={"/business/projects/reader-app/change-password/" + item.id + "/" + item.name + "/" + params.hash_id} className="dropdown-item">Change Password</Link>
                                                             <Dropdown.Item onClick={() => handleDeleteClick(item.id)} className="text-danger">Delete</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>


                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                            <DeleteConfirmationDialog
                                id={accessId}
                                isLoading={isDeleteLoading}
                                isOpen={isDeleteDialogOpen}
                                onDelete={handleConfirmDelete}
                                onClose={handleCloseDialog}
                            />
                            {/* Render the pagination component */}
                            <Pagination
                                pageCount={pageCount}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                            />
                        </ >
                            : <Alert color="primary" className="text-center">
                            <h5 className="mb-3">{messageList}</h5>  <Link to={"/business/projects/reader-app/create/" + params.hash_id} className="btn btn-sm btn-light">Create New Reader</Link>
                        </Alert>
                    }
                </ >
            )}
        </LayoutProjects>
    );
}
export default ReaderAppList;