/* eslint-disable import/no-anonymous-default-export */
import authService from '../api-authorization/AuthorizeService';
import ApiBase from './Base'

const url = "api/accesses/requests/"

export default {
    async list(hash_id, action, status, searchText = '', dateFrom = '', dateTo = '', form_id = '', page, per) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const response = await ApiBase.apiBase(url + 'list/' + status + '/' + action + '/' + hash_id + '?search_text=' + searchText + '&date_from=' + dateFrom + '&date_to=' + dateTo +
                '&form_id=' + form_id + '&page=' + page + '&per=' + per, token, 'GET')
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Actions(id, action, hash_id) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const urls = url + 'actions/' + id + '/' + action + '/' + hash_id;
            const response = await ApiBase.apiBase(urls, token, 'PUT')
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },

}
