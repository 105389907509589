/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Row, Col, Alert, Form, Button, InputGroup, Dropdown } from 'react-bootstrap';
import apiAccessModuleForms from '../Apis/AccessModuleForms';
import apiAccessModules from '../Apis/AccessModules';
import { LayoutProjects } from '../Layouts/LayoutProjects';
import * as Icon from 'react-bootstrap-icons';
import PageLoading from '../Helpars/PageLoading';
import HelparPagination from '../Helpars/Pagination';
import copy from 'clipboard-copy';
import DeleteConfirmationDialog from '../Helpars/DeleteConfirmationDialog';
import HelparAlert from '../Helpars/Alert';


const FormList = () => {
    const params = useParams();
    const [FormsList, setFormsist] = useState([]);
    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [isPageLoading, setPageLoading] = useState(true);
    const [errors, setErrors] = useState('');

    const [currentPage, setCurrentPage] = useState(1); // Current page starts at 1
    const [pageCount, setPageCount] = useState(null); // Current page starts at 1
    const itemsPerPage = 20; // Number of items to display per page

    const [accessModulesData, setAccessModulesData] = useState(null);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isDeleteLoading, setDeleteLoading] = useState(false);
    const [formId, setFormId] = useState(false);
    const [recordsPerPage, setRecordsPerPage] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');


    const currentURL = window.location.origin;

    const [isCopied, setIsCopied] = useState(Array(FormsList && FormsList.length).fill(false));

    const handleCopyToClipboard = (index) => {
        copy(currentURL + '/form/' + FormsList[index].hash_id);
        setIsCopied((prevCopied) => {
            const updatedCopied = [...prevCopied];
            updatedCopied[index] = true;
            return updatedCopied;
        });

        // Reset the "Copied" state after a few seconds
        setTimeout(() => {
            setIsCopied((prevCopied) => {
                const updatedCopied = [...prevCopied];
                updatedCopied[index] = false;
                return updatedCopied;
            });
        }, 2000);
    };


    const handlePageChange = (page) => {
        setCurrentPage(page);
        ListForms(page);
    };
    useEffect(() => {
        ListForms();
        AccessModulesData();
    }, [params.access_module_id])
    const ListForms = async (page, searchQueryData) => {
        setPageLoading(true);
        const accessModuleId = params.access_module_id !== undefined ? params.access_module_id : null;
        let response = await apiAccessModuleForms.list(params.hash_id, accessModuleId, searchQueryData, page, itemsPerPage);
        setFormsist(response.records)
        setStatus(response.status)
        setMessage(response.message)
        setPageCount(Math.ceil(response.total / itemsPerPage))
        setRecordsPerPage(response.records_per_page);
        setPageLoading(false);
    }
    const AccessModulesData = async () => {
        if (params.access_module_id !== undefined) {
            let response = await apiAccessModules.GetById(params.hash_id, params.access_module_id);
            setAccessModulesData(response.data)
        }

    }
    const handleDeleteClick = (id) => {
        setIsDeleteDialogOpen(true);
        setFormId(id);
    };
    const handleConfirmDelete = async (id) => {
        setDeleteLoading(true)
        let response = await apiAccessModuleForms.Delete(id, params.hash_id)
        setStatus(response.status)
        setMessage(response.message)
        setErrors(response.errors)
        let pagecurrent = currentPage;
        if (pageCount > 1) {
            if (recordsPerPage === 1) {
                pagecurrent = currentPage - 1;
            }
        }
        handlePageChange(pagecurrent);
        setIsDeleteDialogOpen(false);
        setDeleteLoading(false)
    };

    const handleCloseDialog = () => {
        setIsDeleteDialogOpen(false);
    };
    const onChangeQuery = (e) => {
        setSearchQuery(e.target.value);
        if (e.target.value === '') {
            ListForms(1, '');
        }
    };
    const handleSearch = () => {
        ListForms(1, searchQuery);
    };
    const LayoutPropsCurrentRootName = params.access_module_id !== null && params.access_module_id !== 0 && params.access_module_id !== undefined ? accessModulesData && accessModulesData.title + " > Forms" : "Forms";
    const LayoutPropsRootName = params.access_module_id !== null && params.access_module_id !== 0 && params.access_module_id !== undefined && "Access Modules";
    const LayoutPropsRootLink = params.access_module_id !== null && params.access_module_id !== 0 && params.access_module_id !== undefined && "/business/projects/access-modules/list/" + params.hash_id;
    const LayoutPropsButtonUrl = params.access_module_id !== null && params.access_module_id !== 0 && params.access_module_id !== undefined ? "/business/projects/forms/create/" + params.access_module_id + "/" + params.hash_id : "/business/projects/forms/create/" + params.hash_id;

    return (
        <LayoutProjects currentRootName={LayoutPropsCurrentRootName} rootName={LayoutPropsRootName} rootLink={LayoutPropsRootLink} buttonUrl={LayoutPropsButtonUrl} buttonName="Create New Form" >
            <Row>
                <Col md={4} className="mb-3">
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder="Name Or Username"
                            aria-label="Name Or Username"
                            aria-describedby="Name Or Username"
                            value={searchQuery}
                            onChange={onChangeQuery}
                            type="search"
                        />
                        <Button variant="primary" id="button-addon2" onClick={handleSearch}>
                            Search
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
            {isPageLoading ? (
                <PageLoading />
            ) : (
                < >
                    {status === "success" ?
                            < >
                                <HelparAlert status={status} message={message} errors={errors} ></HelparAlert>
                            <Row>
                                {FormsList.map((item, index) => (
                                    <Col md={4} className="mb-3">
                                        <Card key={item.id} className="shadow-sm" border="drck">
                                            <div className="d-flex align-content-center align-items-center justify-content-center bg-primary text-light  mb-3 rounded" style={{ height: 150, width: '100%' }} >
                                                {item.cover_url ? <img src={item.cover_url} style={{ height: 150, width: '100%' }} /> :
                                                    <h5>No Cover</h5>
                                                }
                                            </div>
                                            <Card.Body >
                                                <Card.Title className="fs-6 text-gray-800 text-hover-primary mb-3 ">{item.title.charAt(0).toUpperCase() + item.title.slice(1)}</Card.Title>
                                                <InputGroup className="mb-3" size="sm">
                                                    <Form.Control
                                                        value={currentURL + '/form/' + item.hash_id} readOnly
                                                    />
                                                    <Button variant="primary" onClick={() => handleCopyToClipboard(index)}>
                                                        {isCopied[index] ? 'Copied!' : 'Copy'}
                                                    </Button>
                                                </InputGroup>
                                                <div className="d-flex">
                                                    <div className="d-flex align-start">
                                                        <Link to={"/business/projects/forms/accesses/" + item.id + "/" + item.title + "/" + params.hash_id} className="btn btn-primary btn-sm ms-2">Accesses</Link>
                                                        <Link to={"/business/projects/forms/access-logs/" + item.id + "/" + item.title + "/" + params.hash_id} className="btn btn-primary btn-sm ms-2">Access Logs</Link>
                                                     </div>
                                                    <div className="ms-2">
                                                    </div> <Dropdown >
                                                        <Dropdown.Toggle variant="secondary" size='sm' id="dropdown-basic" className="btn-circle">
                                                            <Icon.ThreeDots size={20} />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Link to={"/business/projects/forms/update/" + item.id + "/" + item.access_module_id + "/" + params.hash_id} className="dropdown-item">Edit</Link>
                                                             <Dropdown.Item onClick={() => handleDeleteClick(item.id)} className="text-danger">Delete</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                ))}
                                </Row>
                                <DeleteConfirmationDialog
                                    id={formId}
                                    isLoading={isDeleteLoading}
                                    isOpen={isDeleteDialogOpen}
                                    onDelete={handleConfirmDelete}
                                    onClose={handleCloseDialog}
                                />
                            {/* Render the pagination component */}
                            <HelparPagination
                                pageCount={pageCount}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                            />
                        </ >
                        : <Alert color="secondary" className="text-center">
                                <h5 className="mb-3">{message}</h5>  <Link to={LayoutPropsButtonUrl} className="btn btn-sm btn-light">Create New Form</Link>
                        </Alert>
                    }
                </ >
            )}
        </LayoutProjects>
    );
}
export default FormList;