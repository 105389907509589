/* eslint-disable import/no-anonymous-default-export */
import authService from '../api-authorization/AuthorizeService';
import ApiBase from '../Apis/Base'

const url = "api/business_requests/"
const urlModerator = "api/moderator/business_requests/"

export default {
    async Create(body) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const res = await ApiBase.apiBase(url + 'create', token, 'POST', body)
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async list() {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const response = await ApiBase.apiBase(urlModerator + 'list/', token, 'GET')
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Action(id, body) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const response = await ApiBase.apiBase(urlModerator + 'action/' + id, token, 'POST', body)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
}
