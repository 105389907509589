import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';
import HelparAlert from '../Helpars/Alert';
import { LayoutProjects } from '../Layouts/LayoutProjects';
import apiAccessModules from '../Apis/AccessModules';
import AccessModuleForms from '../Apis/AccessModuleForms';
import FormPreview from './Preview'
import CustomFieldsCreate from '../CustomFields/Create';

const FormCreate = () => {
    const params = useParams();


    const [customFieldData, setCustomFieldData] = useState([]);

    const [isLoading, setLoading] = useState(false);

    const [coverUrl, setCoverUrl] = useState(null);
    const [cover, setCover] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [accessMaximumn, setAccessMaximumn] = useState('');
    const [accessActions, setAccessActions] = useState('');
    const [startsAt, setStartsAt] = useState('');
    const [startsAtTime, setStartsAtTime] = useState('');
    const [endsAt, setEndsAt] = useState('');
    const [endsAtTime, setEndsAtTime] = useState('');

    const [accessStartsAt, setAccessStartsAt] = useState('');
    const [accessStartsAtTime, setAccessStartsAtTime] = useState('');
    const [accessEndsAt, setAccessEndsAt] = useState('');
    const [accessEndsAtTime, setAccessEndsAtTime] = useState('');

    const [accessModuleId, setaccessModuleId] = useState('');
    const [accessModulesList, setAccessModulesList] = useState([]);
    const [accessModulesData, setAccessModulesData] = useState(null);

    const [isSelectAccessModule, setIsSelectAccessModule] = useState(false);

    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState('');

    const [validated, setValidated] = useState(false);

    const onChangeTitle = (event) => {
        const maxCharacterCount = 150;
        if (event.target.value.length <= maxCharacterCount) {
            setTitle(event.target.value);
        }
    }
    const onChangeDescription = (event) => {
        const maxCharacterCount = 500;
        if (event.target.value.length <= maxCharacterCount) {
            setDescription(event.target.value);
        }
    }
    const onChangeAccessMaximumn = (event) => {
        if (event.target.value >= 0) { setAccessMaximumn(event.target.value); }
    }
    const onChangeAccessActions = (event) => {
        setAccessActions(event.target.value);
    }
    const onChangeStartsAt = (event) => {
        setStartsAt(event.target.value);
    }
    const onChangeStartsAtTime = (event) => {
        setStartsAtTime(event.target.value);
    }
    const onChangeEndsAt = (event) => {
        setEndsAt(event.target.value);
    }
    const onChangeEndsAtTime = (event) => {
        setEndsAtTime(event.target.value);
    }

    const onChangeAccessStartsAt = (event) => {
        setAccessStartsAt(event.target.value);
    }
    const onChangeAccessStartsAtTime = (event) => {
        setAccessStartsAtTime(event.target.value);
    }
    const onChangeAccessEndsAt = (event) => {
        setAccessEndsAt(event.target.value);
    }
    const onChangeAccessEndsAtTime = (event) => {
        setAccessEndsAtTime(event.target.value);
    }

    const onChangeAccessModule = (event) => {
        setaccessModuleId(event.target.value);
        if (event.target.value !== '' && event.target.value !== undefined && event.target.value !== '0') {
            setIsSelectAccessModule(true)
            const accessModel = accessModulesList.find(item => item.id === parseInt(event.target.value));
            setAccessModulesData(accessModel)
            setCustomFieldData(accessModel.custom_fields);
        }
        else {
            setIsSelectAccessModule(false)
            setAccessModulesData(null)
        }
    }

    const AccessModulesData = async () => {
        if (params.access_model_id === undefined) {
            let response = await apiAccessModules.list(params.hash_id, '', 1, 200);
            setAccessModulesList(response.records)
            if (response.status === 'error') {
                setStatus(response.status)
                setMessage(response.message)
            }
        }
        else {
            setaccessModuleId(params.access_model_id);
            let response = await apiAccessModules.GetById(params.hash_id, params.access_model_id, 1, 200);
            setAccessModulesData(response.data)
            setCustomFieldData(response.data.custom_fields);
        }

    }
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setCover(file);
        setCoverUrl(URL.createObjectURL(file));
    };
    useEffect(() => {
        AccessModulesData();
    }, [])
    const onSubmit = async (event) => {
        setLoading(true);
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        }
        else {
            event.preventDefault();
            const moduleId = params.access_model_id !== null && params.access_model_id !== 0 && params.access_model_id !== undefined ? params.access_model_id : accessModuleId;
            const formData = new FormData();
            formData.append('title', title);
            formData.append('description', description);
            formData.append('access_maximum', accessMaximumn);
            formData.append('access_actions', accessActions);
            formData.append('is_active', true);

            formData.append('start_at', new Date(startsAt + ' ' + startsAtTime).toISOString());
            formData.append('end_at', new Date(endsAt + ' ' + endsAtTime).toISOString());

            formData.append('access_start_at', new Date(accessStartsAt + ' ' + accessStartsAtTime).toISOString());
            formData.append('access_end_at', new Date(accessEndsAt + ' ' + accessEndsAtTime).toISOString());

            formData.append('cover_file', cover);


            formData.append('custom_fields', JSON.stringify(customFieldData));




            let response = await AccessModuleForms.Create(moduleId, params.hash_id, formData)
            setStatus(response.status)
            setMessage(response.message)
            setErrors(response.errors)

            if (response.status === "success") {
                event.preventDefault();
                setTitle('');
                setDescription('');
                setAccessMaximumn('');
                setAccessActions('');
                setStartsAt('');
                setStartsAtTime('');
                setEndsAt('');
                setEndsAtTime('');
                setAccessStartsAt('');
                setAccessStartsAtTime('');
                setAccessEndsAt('');
                setAccessEndsAtTime('');
                setCover(null);
                setCoverUrl(null);
                setValidated(false);
            }
        }

        setLoading(false);

    }

    const LayoutPropsCurrentRootName = params.access_model_id !== null && params.access_model_id !== 0 && params.access_model_id !== undefined ? accessModulesData && accessModulesData.title + " - Create Form" : "Create";
    const LayoutPropsRootName = params.access_model_id !== null && params.access_model_id !== 0 && params.access_model_id !== undefined ? "Access Modules " : " Forms ";
    const LayoutPropsRootLink = params.access_model_id !== null && params.access_model_id !== 0 && params.access_model_id !== undefined ? "/business/projects/access-modules/list/" + params.hash_id : "/business/projects/accesses/list/" + params.hash_id;

    return (
        <LayoutProjects currentRootName={LayoutPropsCurrentRootName} rootName={LayoutPropsRootName} rootLink={LayoutPropsRootLink}   >
            <Row>
                <Col md={7} >
                    <Card className="shadow-sm mb-5 rounded border border-0">
                        <Card.Header  >
                            Access Module
                        </Card.Header>
                        <Card.Body  >
                            {params.access_model_id === undefined ? accessModulesList ?
                                <Form.Select aria-label="Default select example" value={accessModuleId} onChange={onChangeAccessModule} className="mb-3">
                                    <option value=''>Open this select menu</option>
                                    {accessModulesList && accessModulesList.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.title}
                                        </option>
                                    ))}
                                </Form.Select>
                                : <Alert color="secondary" className="text-center w-100">
                                    <h5 className="mb-3">{message}</h5>  <Link to={"/business/projects/access-modules/create/" + params.hash_id} className="btn btn-sm btn-light">Create New Access Modules</Link>
                                </Alert> : ''
                            }
                            {accessModulesData &&
                                <div>
                                    <Row className="mb-3">
                                        <Col >
                                            <strong>Description</strong><div> {accessModulesData.description}</div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <strong>Expiration Type </strong><div>Date </div>
                                        </Col>
                                        {accessModulesData.checkins_maximum &&
                                            <Col>
                                                <strong>Check-ins </strong><div>{accessModulesData.checkins_maximum} maximum</div>
                                            </Col>
                                        }
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <strong>Readers </strong>
                                            <Row>{accessModulesData && accessModulesData.reader_apps && accessModulesData.reader_apps.map(item => (<Col key={item.id} lg={3} className="border p-2 m-1"><span>{item.name}</span></Col>))} </Row>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                    {(params.access_model_id === undefined && isSelectAccessModule) || (params.access_model_id !== undefined && isSelectAccessModule === false) ?
                        <Form noValidate validated={validated} onSubmit={onSubmit} className="w-100" >
                            <Card className="shadow-sm mb-5 rounded border border-0">
                                <Card.Header  >
                                    Form settings
                                </Card.Header>
                                <Card.Body className="d-flex">
                                    <Form noValidate validated={validated} className="w-100" >
                                        <HelparAlert status={status} message={message} errors={errors} ></HelparAlert>
                                        <Form.Group className="mb-3">
                                            <Form.Label >Cover</Form.Label>
                                            <Form.Control type="file" onChange={handleImageUpload} />
                                        </Form.Group >
                                        <Form.Group className="mb-3">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control value={title} onChange={onChangeTitle} placeholder="Enter title" required />
                                            <Form.Text className="text-muted">
                                                Enter a maximum of 150 characters
                                            </Form.Text>
                                            <Form.Control.Feedback type="invalid">
                                                Form Title is required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control as="textarea" rows={3} value={description} onChange={onChangeDescription} placeholder="Enter description" required />
                                            <Form.Text className="text-muted">
                                                Enter a maximum of 500 characters
                                            </Form.Text>
                                            <Form.Control.Feedback type="invalid">
                                                Form Description is required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Form Starts at</Form.Label>
                                                    <Form.Control value={startsAt} onChange={onChangeStartsAt} type="date" placeholder="Enter user name" required />
                                                    <Form.Control.Feedback type="invalid">
                                                        Starts at is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Time</Form.Label>
                                                    <Form.Control value={startsAtTime} onChange={onChangeStartsAtTime} type="time" placeholder="Enter user name" required />
                                                    <Form.Control.Feedback type="invalid">
                                                        Starts at time is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Form Ends at</Form.Label>
                                                    <Form.Control value={endsAt} onChange={onChangeEndsAt} placeholder="Enter password" type="date" required />
                                                    <Form.Control.Feedback type="invalid">
                                                        Ends at is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Time</Form.Label>
                                                    <Form.Control value={endsAtTime} onChange={onChangeEndsAtTime} placeholder="Enter password" type="time" required />
                                                    <Form.Control.Feedback type="invalid">
                                                        Ends at time is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                            <Card className="shadow-sm mb-5 rounded border border-0">
                                <Card.Header  >
                                    Access settings
                                </Card.Header>
                                <Card.Body className="d-flex">
                                    <Form noValidate validated={validated} className="w-100" >
                                        <Form.Group className="mb-3">
                                            <Form.Label>Access Actions</Form.Label>
                                            <div>
                                                <Form.Check inline name="accessActions" label="Auto send access" type='radio' checked={accessActions === "auto_send"} value="auto_send" onChange={onChangeAccessActions} required />
                                                <Form.Check inline name="accessActions" label="Access approval" type='radio' checked={accessActions === "approval"} value="approval" onChange={onChangeAccessActions} required />
                                                <Form.Control.Feedback type="invalid">
                                                    Access Actions is required
                                                </Form.Control.Feedback>

                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Accesses limited</Form.Label>
                                            <Form.Control value={accessMaximumn} onChange={onChangeAccessMaximumn} placeholder="Enter Access Maximum" type="number" />
                                            <Form.Text className="text-muted">

                                            </Form.Text>
                                        </Form.Group>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Access Starts at</Form.Label>
                                                    <Form.Control value={accessStartsAt} onChange={onChangeAccessStartsAt} type="date" placeholder="Enter user name" required />
                                                    <Form.Control.Feedback type="invalid">
                                                        Starts at is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Time</Form.Label>
                                                    <Form.Control value={accessStartsAtTime} onChange={onChangeAccessStartsAtTime} type="time" placeholder="Enter user name" required />
                                                    <Form.Control.Feedback type="invalid">
                                                        Starts at time is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Access Ends at</Form.Label>
                                                    <Form.Control value={accessEndsAt} onChange={onChangeAccessEndsAt} placeholder="Enter password" type="date" required />
                                                    <Form.Control.Feedback type="invalid">
                                                        Ends at is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Time</Form.Label>
                                                    <Form.Control value={accessEndsAtTime} onChange={onChangeAccessEndsAtTime} placeholder="Enter password" type="time" required />
                                                    <Form.Control.Feedback type="invalid">
                                                        Ends at time is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>


                                    </Form>
                                </Card.Body>
                            </Card>
                            <Card className="shadow-sm mb-5 rounded border border-0">
                                <Card.Header  >
                                    Custom Field
                                </Card.Header>
                                <Card.Body className="d-flex p-3">
                                    <Form noValidate validated={validated} className="w-100" >
                                        <CustomFieldsCreate customFieldData={customFieldData} setCustomFieldData={setCustomFieldData} />
                                    </Form>
                                </Card.Body>
                                <Card.Footer  >
                                    <Button type="submit"
                                        variant="primary" size="lg"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Loading…' : 'Submit'}
                                    </Button>
                                </Card.Footer>

                            </Card>
                        </Form>
                        : ''
                    }

                </Col>
                <Col md={5} >
                    <FormPreview cover_url={coverUrl} title={title} description={description} />
                </Col>
            </Row>
        </LayoutProjects>
    );
}
export default FormCreate;