import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Card, Form, Button } from 'react-bootstrap';
import apiProjects from '../Apis/Projects';
import HelparAlert from '../Helpars/Alert';

const ProjectCreate = () => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [details, setDetails] = useState('');

    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState('');

    const [validated, setValidated] = useState(false);

    const onChangeName = (event) => {
        setName(event.target.value);
    }
    const onChangePhone = (event) => {
        setPhone(event.target.value);
    }
    const onChangeEmail = (event) => {
        setEmail(event.target.value);
    }
    const onChangeDetails = (event) => {
        setDetails(event.target.value);
    }


    const onSubmit = async (event) => {
        setLoading(true);
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            let BusinessObj = {
                name: name,
                phone: phone,
                email: email,
                brief: details,
            }
            let response = await apiProjects.Create(BusinessObj)
            setStatus(response.status)
            setMessage(response.message)
            setErrors(response.errors)

            if (response.status === "success") {
                navigate('/business/projects/list');
            }
        }
        setValidated(true);
        setLoading(false);
    }

    return (
        <div>
            <Container fluid="md">
                <Card className="shadow-sm p-3 mb-5 rounded border border-0">
                    <Card.Body className="d-flex">
                        <Form noValidate validated={validated} onSubmit={onSubmit} className="w-100" >
                            <h1 className="mb-5">Create project</h1>
                            <HelparAlert status={status} message={message} errors={errors} ></HelparAlert>
                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control value={name} onChange={onChangeName} placeholder="Enter name" required />
                                <Form.Control.Feedback type="invalid">
                                    name is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control value={phone} onChange={onChangePhone} placeholder="Enter Phone"   />
                                
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control value={email} onChange={onChangeEmail} placeholder="Enter email" type="email"   />
                                 
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>About Your Project</Form.Label>
                                <Form.Control value={details} onChange={onChangeDetails} placeholder="Enter Details" as="textarea" rows={3} />
                            </Form.Group>
                            <Button type="submit"
                                variant="primary" size="lg"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Loading…' : 'submit'}
                            </Button>

                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}
export default ProjectCreate;