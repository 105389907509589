// Loading.js
import React from 'react';
import { Spinner } from 'react-bootstrap';
import './Style.css';


const PageLoading = () => {
    return (
        <div className="loading">
            <Spinner animation="border" />
        </div>
    );
};
 
export default PageLoading;
