/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from '../NavMenu';
import Footer from '../Footer';

export class Layout extends Component {
    static displayName = Layout.name;

    componentDidMount() {
        const root = document.documentElement;
        root.style.removeProperty('--bs-primary');
    }

    render() {
        return (
            <div>
                <NavMenu />
                <Container tag="main" className="main-container">
                    {this.props.children}
                </Container>
                <Footer />
            </div>
        );
    }
}