import React, { useEffect, useState } from 'react';
import { Container, Card, Form, Button, Row, Col } from 'react-bootstrap';
import apiBusinessRequests from '../Apis/BusinessRequests';
import apiCountries from '../Apis/Countries';
import apiBusinessCategories from '../Apis/BusinessCategories';
import HelparAlert from '../Helpars/Alert';

const BusinessRequest = () => {

    const [isLoading, setLoading] = useState(false);

    const [ownerName, setOwnerName] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [countryId, setCountryId] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [details, setDetails] = useState('');
    const [potentialUsersNumber, setPotentialUsersNumber] = useState('');

    const [businessCategoriesList, setBusinessCategoriesList] = useState('');
    const [countriesList, setCountriesList] = useState('');

    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState('');

    const [validated, setValidated] = useState(false);

    const onChangeName = (event) => {
        setName(event.target.value);
    }
    const onChangePhone = (event) => {
        setPhone(event.target.value);
    }
    const onChangeEmail = (event) => {
        setEmail(event.target.value);
    }
    const onChangeDetails = (event) => {
        setDetails(event.target.value);
    }
    const onChangeOwnerName = (event) => {
        setOwnerName(event.target.value);
    }
    const onChangeCountryId = (event) => {
        setCountryId(event.target.value);
    }
    const onChangeCategoryId = (event) => {
        setCategoryId(event.target.value);
    }
    const onChangePotentialUsersNumber = (event) => {
        const maxCharacterCount = 16;
        if (event.target.value.length <= maxCharacterCount && event.target.value >= 0) {
            setPotentialUsersNumber(event.target.value);
        }
    }
    useEffect(() => {
        GetBusinessCategories();
        GetCountries();
     }, [])

    const GetBusinessCategories = async () => {
        let response = await apiBusinessCategories.list();
        setBusinessCategoriesList(response.records)

    }
    const GetCountries = async () => {
        let response = await apiCountries.list();
        setCountriesList(response.records)

    }

    const onSubmit = async (event) => {
        setLoading(true);
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        }
        else {
            event.preventDefault();

            const countryCode = countriesList.find(item => item.id === parseInt(countryId)).phone_code;
            let BusinessObj = {
                name: name,
                phone: countryCode + phone,
                email: email,
                owner_name: ownerName,
                details: details,
                country_id: countryId,
                business_category_id: categoryId,
                potential_users_number: potentialUsersNumber,
            }
            let response = await apiBusinessRequests.Create(BusinessObj)
            setStatus(response.status)
            setMessage(response.message)
            setErrors(response.errors)

            if (response.status === "success") {
                setName('');
                setPhone('');
                setEmail('');
                setDetails('');
                setCategoryId('');
                setCountryId('');
                setPotentialUsersNumber('');
                setOwnerName('');
            }
        }
        setLoading(false);
    }

    return (
        <div>
            <Container fluid="md mt-3">
                <Card className="shadow-sm p-3 mb-5 rounded border border-0">
                    <Card.Body className="d-flex">
                        <Form noValidate validated={validated} onSubmit={onSubmit} className="w-100" >
                            <h1 className="mb-5">Request Create Business Account</h1>
                            <HelparAlert status={status} message={message} errors={errors} ></HelparAlert>
                            <Form.Group className="mb-3">
                                <Form.Label>Your Name</Form.Label>
                                <Form.Control value={ownerName} onChange={onChangeOwnerName} placeholder="Enter Name" required />
                                <Form.Control.Feedback type="invalid">
                                    name is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control value={email} onChange={onChangeEmail} placeholder="Enter Business Email" type="email" required />
                                <Form.Control.Feedback type="invalid">
                                    Email is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Row>
                                <Col xs={3} ><Form.Group className="mb-3">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select value={countryId} onChange={onChangeCountryId} className="mb-3" required>
                                        <option value=''>Select Country</option>
                                        {countriesList && countriesList.map((option, index) => (
                                            <option key={index} value={option.id}>
                                                {option.name + ' (' + option.phone_code + ')'}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Country is required
                                    </Form.Control.Feedback>
                                </Form.Group></Col>
                                <Col xs={9} ><Form.Group className="mb-3">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control value={phone} onChange={onChangePhone} placeholder="Enter Phone" required />
                                    <Form.Control.Feedback type="invalid">
                                        User Name is required
                                    </Form.Control.Feedback>
                                </Form.Group></Col>
                            </Row>

                            <Form.Group className="mb-3">
                                <Form.Label>Business Category</Form.Label>
                                <Form.Select value={categoryId} onChange={onChangeCategoryId} className="mb-3" required>
                                    <option value=''>Select Category</option>
                                    {businessCategoriesList && businessCategoriesList.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Business Category is required
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Business Name</Form.Label>
                                <Form.Control value={name} onChange={onChangeName} placeholder="Enter Business Name" required />
                                <Form.Control.Feedback type="invalid">
                                    name is required
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Potential users number</Form.Label>
                                <Form.Control type="number" value={potentialUsersNumber} onChange={onChangePotentialUsersNumber} placeholder="Enter Potential Users Number" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Requirements</Form.Label>
                                <Form.Control as="textarea" rows={3} value={details} onChange={onChangeDetails} placeholder="Enter Requirements" />
                            </Form.Group>
                            <Button type="submit"
                                variant="primary" size="lg"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Loading…' : 'submit'}
                            </Button>

                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}
export default BusinessRequest;