import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Form, Button, InputGroup } from 'react-bootstrap';
import apiReaderApps from '../Apis/ReaderApps';
import HelparAlert from '../Helpars/Alert';
import { LayoutProjects } from '../Layouts/LayoutProjects';


const ReaderAppCreate = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [languages, setLanguages] = useState('');
    const [alertCloseType, setAlertCloseType] = useState('');
    const [alertCloseIntervalTime, setAlertCloseIntervalTime] = useState(null);

    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState('');


    const [validated, setValidated] = useState(false);

    const onChangeName = (event) => {
        setName(event.target.value);
    }
    const onChangeUserName = (event) => {
        setUserName(event.target.value);
    }
    const onChangePassword = (event) => {
        setPassword(event.target.value);
    }
    const onChangeLanguages = (event) => {
        setLanguages(event.target.value);
    }
    const onChangeAlertCloseType = (event) => {
        setAlertCloseType(event.target.value);
    }
    const onChangeAlertCloseIntervalTime = (event) => {

        if (event.target.value <= 60) {
            setAlertCloseIntervalTime(event.target.value);
        }
    }
    const onSubmit = async (event) => {
        setLoading(true);
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            let ReaderAppObj = {
                name: name,
                user_name: userName,
                password: password,
                languages: languages,
                alert_close_type: alertCloseType,
                alert_close_interval_time: alertCloseIntervalTime,
            }
            let response = await apiReaderApps.Create(params.hash_id, ReaderAppObj)
            setStatus(response.status)
            setMessage(response.message)
            setErrors(response.errors)

            if (response.status === "success") {
                navigate("/business/projects/reader-app/list/" + params.hash_id);
            }
        }
        setValidated(true);
        setLoading(false);
    }
    return (
        <LayoutProjects rootName="Readers" currentRootName="Create" rootLink={"/business/projects/reader-app/list/" + params.hash_id}>
            <Card className="shadow-sm p-3 mb-5 rounded border border-0">
                <Card.Body className="d-flex">
                    <Form noValidate validated={validated} onSubmit={onSubmit} className="w-100" >
                        <HelparAlert status={status} message={message} errors={errors} ></HelparAlert>
                        <Form.Group className="mb-3">
                            <Form.Label>Reader Name</Form.Label>
                            <Form.Control value={name} onChange={onChangeName} placeholder="Enter name" required />
                            <Form.Control.Feedback type="invalid">
                                name is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Alert Close Type</Form.Label>
                            <div>
                                <Form.Check inline name="alertCloseType" label="Close Button" type='radio' checked={alertCloseType === "button_close"} value="button_close" onChange={onChangeAlertCloseType} required />
                                <Form.Check inline name="alertCloseType" label="Interval Time" type='radio' checked={alertCloseType === "interval_time"} value="interval_time" onChange={onChangeAlertCloseType} required />
                                <Form.Check inline name="alertCloseType" label="Close Button & Interval Time" type='radio' checked={alertCloseType === "button_close_and_interval_time"} value="button_close_and_interval_time" onChange={onChangeAlertCloseType} required />
                                <Form.Control.Feedback type="invalid">
                                    Invitation Duration Type is required
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                        {alertCloseType === 'interval_time' || alertCloseType === 'button_close_and_interval_time' ?
                            <Form.Group className="mb-3">
                                <Form.Label>Alert Close Interval Time</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control type="number" value={alertCloseIntervalTime} onChange={onChangeAlertCloseIntervalTime} placeholder="Enter Interval Time" />
                                    <InputGroup.Text id="basic-addon3">
                                        Seconds
                                    </InputGroup.Text>
                                </InputGroup>
                                <Form.Text className="text-muted">
                                    Enter a maximum of 60 seconds.
                                </Form.Text>
                            </Form.Group> : ''
                        }
                        <Form.Group className="mb-3">
                            <Form.Label>Languages</Form.Label>
                            <Form.Select aria-label="Default select example" value={languages} onChange={onChangeLanguages} className="mb-3">
                                <option value="en" >
                                    English
                                </option>
                                <option value="ar" >
                                    Arabic
                                </option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control value={userName} onChange={onChangeUserName} placeholder="Enter user name" required />
                            <Form.Control.Feedback type="invalid">
                                User Name is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control value={password} onChange={onChangePassword} placeholder="Enter password" type="password" required />
                            <Form.Control.Feedback type="invalid">
                                Password is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button type="submit"
                            variant="primary" size="lg"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Loading…' : 'submit'}
                        </Button>

                    </Form>
                </Card.Body>
            </Card>
        </LayoutProjects>

    );
}
export default ReaderAppCreate;