import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card, Form, Button, Row, Col } from 'react-bootstrap';
import apiAccessModuleForms from '../Apis/AccessModuleForms';
import apiAccessModules from '../Apis/AccessModules';
import HelparAlert from '../Helpars/Alert';
import PageLoading from '../Helpars/PageLoading';
import { LayoutProjects } from '../Layouts/LayoutProjects';
import moment from 'moment';
import FormPreview from './Preview';


const FormUpdate = () => {
    const params = useParams();
    const [validated, setValidated] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isPageLoading, setPageLoading] = useState(true);
    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState('');
    const [coverUrl, setCoverUrl] = useState(null);
    const [cover, setCover] = useState(null);
    const [accessModulesData, setAccessModulesData] = useState(null);
    const [accessModuleFormsData, setAccessModuleFormsData] = useState({
        title: '',
        description: '',
        access_maximum: '',
        start_at: '',
        start_at_time: '',
        end_at: '',
        end_at_time: '',
    });
    useEffect(() => {
        populateFormsData();
        populateAccessModuleData();
    }, [])


    const populateFormsData = async () => {
        let response = await apiAccessModuleForms.GetById(params.hash_id,params.id);
        setAccessModuleFormsData({
            title: response.data.title? response.data.title : '',
            description: response.data.description? response.data.description : '',
            access_maximum: response.data.access_maximum ? response.data.access_maximum : '',
            start_at: response.data.start_at ? moment(response.data.start_at).format('YYYY-MM-DD') : '',
            start_at_time: response.data.start_at ? moment(response.data.start_at).format('HH:MM') : '',
            end_at: response.data.end_at ? moment(response.data.end_at).format('YYYY-MM-DD') : '',
            end_at_time: response.data.end_at ? moment(response.data.end_at).format('HH:MM') : '',
        })
        setCoverUrl(response.data.cover_url);
        setPageLoading(false);
    }
    const populateAccessModuleData = async () => {
        let response = await apiAccessModules.GetById(params.hash_id, params.access_model_id);
        setAccessModulesData(response.data )
         
        setPageLoading(false);
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAccessModuleFormsData({
            ...accessModuleFormsData,
            [name]: value,
        });
    };
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setCover(file);
        setCoverUrl(URL.createObjectURL(file));
    };
    const handleSubmit = async (event) => {
        setLoading(true);
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            const formData = new FormData();
            formData.append('title', accessModuleFormsData.title);
            formData.append('description', accessModuleFormsData.description);
            formData.append('access_maximum', accessModuleFormsData.access_maximum);
            formData.append('is_active', true);
            formData.append('start_at', accessModuleFormsData.start_at + ' ' + accessModuleFormsData.start_at_time);
            formData.append('end_at', accessModuleFormsData.end_at + ' ' + accessModuleFormsData.end_at_time);
             formData.append('cover_file', cover);
            let response = await apiAccessModuleForms.Update(params.id, params.hash_id, formData)
            setStatus(response.status)
            setMessage(response.message)
            setErrors(response.errors)
        }
        setValidated(true);
        setLoading(false);
    };

    return (
        <LayoutProjects rootName="Access Modules Form" rootLink={"/business/projects/access-modules/list/" + params.hash_id} currentRootName="Update" >
            <Row>
                <Col md={7} >

                    <Card className="shadow-sm mb-5 rounded border border-0">
                        <Card.Header  >
                            Access Module
                        </Card.Header>
                        <Card.Body  >
                            {accessModulesData &&
                                <div>
                                    <Row className="mb-3">
                                        <Col >
                                            <strong>Description</strong><div> {accessModulesData.description}</div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <strong>Expiration Type </strong><div>Date </div>
                                        </Col>
                                        {accessModulesData.checkins_maximum &&
                                            <Col>
                                                <strong>Check-ins </strong><div>{accessModulesData.checkins_maximum} maximum</div>
                                            </Col>
                                        }
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <strong>Readers </strong>
                                            <Row>{accessModulesData && accessModulesData.reader_apps && accessModulesData.reader_apps.map(item => (<Col key={item.id} lg={3} className="border p-2 m-1"><span>{item.name}</span></Col>))} </Row>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </Card.Body>
                    </Card>

                    <Card className="shadow-sm p-3 mb-5 rounded border border-0">
                        <Card.Body className="d-flex">
                            <Form noValidate validated={validated} onSubmit={handleSubmit} className="w-100" >
                                <HelparAlert status={status} message={message} errors={errors} ></HelparAlert>
                                <Form.Group className="mb-3">
                                    <Form.Label >Cover</Form.Label>
                                    <Form.Control type="file" onChange={handleImageUpload} />
                                </Form.Group >
                                <Form.Group className="mb-3">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control name="title" value={accessModuleFormsData.title} onChange={handleInputChange} placeholder="Enter title" required />
                                    <Form.Text className="text-muted">
                                        Enter a maximum of 150 characters
                                    </Form.Text>
                                    <Form.Control.Feedback type="invalid">
                                        Form Title is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control name="description" as="textarea" rows={3} value={accessModuleFormsData.description} onChange={handleInputChange} placeholder="Enter description" required />
                                    <Form.Text className="text-muted">
                                        Enter a maximum of 500 characters
                                    </Form.Text>
                                    <Form.Control.Feedback type="invalid">
                                        Form Description is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Accesses limited</Form.Label>
                                    <Form.Control name="access_maximum" value={accessModuleFormsData.access_maximum} onChange={handleInputChange} placeholder="Enter Access Maximum" type="number" />
                                    <Form.Text className="text-muted">

                                    </Form.Text>
                                </Form.Group>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Starts at</Form.Label>
                                            <Form.Control name="start_at" value={ accessModuleFormsData.start_at } onChange={handleInputChange} type="date" placeholder="Enter user name" required />
                                            <Form.Control.Feedback type="invalid">
                                                Starts at is required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Time</Form.Label>
                                            <Form.Control name="start_at_time" value={accessModuleFormsData.start_at_time} onChange={handleInputChange} type="time" placeholder="Enter user name" required />
                                            <Form.Control.Feedback type="invalid">
                                                Starts at time is required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Ends at</Form.Label>
                                            <Form.Control name="end_at" value={accessModuleFormsData.end_at} onChange={handleInputChange} placeholder="Enter password" type="date" required />
                                            <Form.Control.Feedback type="invalid">
                                                Ends at is required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Time</Form.Label>
                                            <Form.Control name="end_at_time" value={accessModuleFormsData.end_at_time} onChange={handleInputChange} placeholder="Enter password" type="time" required />
                                            <Form.Control.Feedback type="invalid">
                                                Ends at time is required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button type="submit"
                                    variant="primary" size="lg"
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Loading…' : 'Send'}
                                </Button>

                            </Form>
                        </Card.Body>
                    </Card>  

                </Col>
                <Col md={5} >
                    <FormPreview cover_url={coverUrl} title={accessModuleFormsData.title} description={accessModuleFormsData.description} />
                </Col>
            </Row>
        </LayoutProjects>
    );
}
export default FormUpdate;