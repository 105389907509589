/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import SideMenuModerator from './SideMenu';
import { LoginMenu } from '../api-authorization/LoginMenu';
import Footer from '../Footer';
import ModeratorHeader from './Header';

export class LayoutModerator extends Component {
    static displayName = LayoutModerator.name;

    render() {
        return (
              <div className="row flex-nowrap me-0  ">
                <div className="position-fixed col-auto col-md-3 col-xl-2 m-0 p-0" style={{ width: '15.1%' }}>
                    <div className="align-items-center align-items-sm-start text-white min-vh-100 p-2 pt-0" style={{ backgroundColor:'rgb(5 69 98)'}}>
                        <div className="d-flex px-3 mb-4 pt-2 border-white">
                            <Link to="/" className="align-items-center py-3 text-dark text-decoration-none">
                                <div className="project-layout-logo"></div>
                            </Link>
                        </div>
                        <SideMenuModerator />
                    </div>
                </div>
                <div className="col m-0 p-0" >
                    <header className="bg-light  px-4">
                        <div class="d-flex justify-content-between align-items-center p-1" style={{ marginLeft: '15%' }} >
                            <div className="text-dark fw-bold " >Moderator</div>

                            <div className="d-flex flex-row-reverse bg-body-tertiary">
                                <ul className="nav">
                                    <LoginMenu></LoginMenu>
                                </ul>
                            </div>
                        </div>
                    </header>
                    <div style={{ marginLeft: '15%' }}>
                        <ModeratorHeader rootName={this.props.rootName} rootLink={this.props.rootLink} currentRootName={this.props.currentRootName} buttonName={this.props.buttonName} buttonUrl={this.props.buttonUrl} />

                        <Container tag="main" className="main-container">
                            {this.props.children}
                        </Container>
                        <Footer marginLeft='15%' />
                    </div>
                </div>
            </div>
             
        );
    }
}
