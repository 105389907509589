import React, { useEffect, useState } from 'react';
import ApiBusinesses from '../Apis/Businesses';
import { Link, useNavigate } from 'react-router-dom';



const BusinessHeader = (props) => {
    const navigate = useNavigate();
    const [business, setBusiness] = useState(null);
    useEffect(() => {
        populateBusinessData()
    }, [])

    const populateBusinessData = async () => {
        const businessData = await ApiBusinesses.Get();
        if (businessData.data === null) {
            navigate('/business/request');
        }
        setBusiness(businessData.data)
    }

    return (
        <>
            <span className={props.textColor} >Business: <Link to="/business/projects/list" className={"fw-bold " + props.textColor} > <strong >{business && business.name}</strong></Link>   </span>
        </>
    );

}
export default BusinessHeader;