/* eslint-disable import/no-anonymous-default-export */
import authService from '../api-authorization/AuthorizeService';
import ApiBase from '../Apis/Base'

const url = "api/moderator/access_module_packages/"

export default {
    async Create(accessModuleId,body) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const res = await ApiBase.apiBase(url + 'create/' + accessModuleId, token, 'POST', body)
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    } 
    ,
    async List(accessModuleId) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const res = await ApiBase.apiBase(url + 'list/' + accessModuleId, token, 'GET')
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    }
}
