import React from "react";
 import accessModuleIcon from '../assets/img/features/access-module.svg';
import formsIcon from '../assets/img/features/forms.svg';
import accessIcon from '../assets/img/features/access.svg';
import invitationsIcon from '../assets/img/features/invitations.svg';
import accessLogsIcon from '../assets/img/features/access-logs.svg';

import mobileReaderIcon from '../assets/img/features/mobile-reader.svg';

const LandingKeyFeatures = () => {
    return (
        <section id="KeyFeatures"  >
            <div class="container p-5">
                <div class="align-items-center pb-5">
                    <h1 className="fs-2 fw-bold text-center mb-5">
                        Key Features
                    </h1>
                    <div className="row ">
                        <div class="d-flex col-md align-items-center m-1 bg-primary rounded-3" >
                            <div class="text-center m-5">
                                <img src={mobileReaderIcon} width="100" height="100" alt="..." />
                            </div>
                            <div className="text-white">
                                <h2 className="fs-2 fw-bold">Mobile Reader</h2>
                                <p className="fs-5" style={{ color: "#eee" }}>
                                    Streamlined access
                                    for a seamless  user experience.</p>
                            </div>
                        </div>
                        <div class="d-flex col-md align-items-center m-1 bg-primary rounded-3" >
                            <div class="text-center m-5">
                                <img src={accessModuleIcon} width="100" height="100" alt="..." />
                            </div>
                            <div className="text-white">
                                <h2 className="fs-2 fw-bold">Access Modules</h2>
                                <p className="fs-5" style={{ color: "#eee" }}>
                                    Elevate your control
                                    with modular  access solutions.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div class="d-flex col-md align-items-center m-1 bg-primary rounded-3" >
                            <div class="text-center m-5">
                                <img src={formsIcon} width="100" height="100" alt="..." />
                            </div>
                            <div className="text-white">
                                <h2 className="fs-2 fw-bold">Forms</h2>
                                <p className="fs-5" style={{ color: "#eee" }}>
                                    Simplify processes
                                    with customizable
                                    and intuitive forms.</p>
                            </div>
                        </div>
                        <div class="d-flex col-md align-items-center m-1 bg-primary rounded-3" >
                            <div class="text-center m-5">
                                <img src={accessIcon} width="100" height="100" alt="..." />
                            </div>
                            <div className="text-white">
                                <h2 className="fs-2 fw-bold">Accesses</h2>
                                <p className="fs-5" style={{ color: "#eee" }}>
                                    Efficiently manage
                                    and monitor  user accesses.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div class="d-flex col-md align-items-center m-1 bg-primary rounded-3" >
                            <div class="text-center m-5">
                                <img src={invitationsIcon} width="100" height="100" alt="..." />
                            </div>
                            <div className="text-white">
                                <h2 className="fs-2 fw-bold">Invitations
                                </h2>
                                <p className="fs-5" style={{ color: "#eee" }}>
                                    Extend invitations  effortlessly with our
                                    user-friendly system.</p>
                            </div>
                        </div>
                        <div class="d-flex col-md align-items-center m-1 bg-primary rounded-3" >
                            <div class="text-center m-5">
                                <img src={accessLogsIcon} width="100" height="100" alt="..." />
                            </div>
                            <div className="text-white">
                                <h2 className="fs-2 fw-bold">Access Logs
                                </h2>
                                <p className="fs-5" style={{ color: "#eee" }}>
                                    Gain insights  through detailed  access logs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};
export default LandingKeyFeatures;
