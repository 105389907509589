import React, { useState } from 'react';
 import { Container, Card, Form, Button } from 'react-bootstrap';
import apiBusinesses from '../../Apis/Businesses';
import HelparAlert from '../../Helpars/Alert';

const ModeratorBusinessCreate = () => {
 
    const [isLoading, setLoading] = useState(false);
    const [ownerEmail, setOwnerEmail] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [businessPhone, setBusinessPhone] = useState('');
    const [businessEmail , setBusinessEmail ] = useState('');
    const [businessBrief, setBusinessBrief] = useState('');

    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState('');
    const [validated, setValidated] = useState(false); 

    const onChangeOwnerEmail = (event) => {
        setOwnerEmail(event.target.value);
    }
    const onChangeBusinessName = (event) => {
        setBusinessName(event.target.value);
    }
    const onChangeBusinessPhone = (event) => {
        setBusinessPhone(event.target.value);
    }
    const onChangeBusinessEmail  = (event) => {
        setBusinessEmail (event.target.value);
    }
    const onChangeBusinessBrief = (event) => {
        setBusinessBrief(event.target.value);
    }

    const onSubmit = async (event) => {
        setLoading(true);
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            let BusinessObj = {
                owner_email: ownerEmail,
                name: businessName ,             
                phone: businessPhone ,             
                email: businessEmail  ,             
                brief: businessBrief              
            }
            let response = await apiBusinesses.Create(BusinessObj)
            setStatus(response.status)
            setMessage(response.message)
            setErrors(response.errors)

            if (response.status === "success") {
                setOwnerEmail('');
                setBusinessName('');
            }
        }
        setValidated(true);
        setLoading(false);
    }

    return (
        <div>
            <Container fluid="md">
                <Card className="shadow-sm p-3 mb-5 rounded border border-0">
                    <Card.Body className="d-flex">
                        <Form noValidate validated={validated} onSubmit={onSubmit} className="w-100" >
                            <h1 className="mb-5">Create business</h1>
                            <HelparAlert status={status} message={message} errors={errors} ></HelparAlert>
                            <Form.Group>
                                <Form.Label>Owner Email</Form.Label>
                                <Form.Control value={ownerEmail} onChange={onChangeOwnerEmail} placeholder="Enter owner business" type="email" required />
                                <Form.Control.Feedback type="invalid">
                                   Owner Email is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Business Name</Form.Label>
                                <Form.Control value={businessName} onChange={onChangeBusinessName} placeholder="Enter business name" required />
                                <Form.Control.Feedback type="invalid">
                                   Business Name is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Business Phone</Form.Label>
                                <Form.Control value={businessPhone} onChange={onChangeBusinessPhone} placeholder="Enter business name"   />
                                
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Business Email</Form.Label>
                                <Form.Control value={businessEmail} onChange={onChangeBusinessEmail} placeholder="Enter business name"   />
                                 
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Business Brief</Form.Label>
                                <Form.Control value={businessBrief} onChange={onChangeBusinessBrief} placeholder="Enter business name"   />
                                
                            </Form.Group>
                            <Button type="submit"
                                variant="primary" size="lg"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Loading…' : 'submit'}
                            </Button>

                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}
export default ModeratorBusinessCreate;