import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Card, Form, Button, Figure, Row, Col } from 'react-bootstrap';
import ApiProjects from '../Apis/Projects';
import HelparAlert from '../Helpars/Alert';
import PageLoading from '../Helpars/PageLoading';
import ProjectHeader from './Header';
import { LayoutProjects } from '../Layouts/LayoutProjects';

const ProjectCreate = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [validated, setValidated] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isPageLoading, setPageLoading] = useState(true);
    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState('');
    const [logoUrl, setLogoUrl] = useState(null)
    const [projectData, setProjectData] = useState({
        name: '',
        logo_file: null,
        phone: '',
        email: '',
        brief: '',
        theme_color: '',
        website: '',
        facebook: '',
        twitter: '',
    });
    useEffect(() => {
        populateProjectData();
    }, [])


    const populateProjectData = async () => {
        let response = await ApiProjects.GetById(params.hash_id);
        setProjectData({
            name: response.data.name !== null ? response.data.name : '',
            email: response.data.email !== null ? response.data.email : '',
            brief: response.data.brief !== null ? response.data.brief : '',
            phone: response.data.phone !== null ? response.data.phone : '',
            theme_color: response.data.theme_color !== null ? response.data.theme_color : '',
            website: response.data.website !== null ? response.data.website : '',
            facebook: response.data.facebook !== null ? response.data.facebook : '',
            twitter: response.data.twitter !== null ? response.data.twitter : '',
        })
        setLogoUrl(response.data.logo_url);
        setPageLoading(false);

    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProjectData({
            ...projectData,
            [name]: value,
        });
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setProjectData({
            ...projectData,
            logo_file: file,
        });
        setLogoUrl(URL.createObjectURL(file));
    };
    const handleSubmit = async (event) => {
        setLoading(true);
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            // Send formData to the API to update the data and upload the image
            const formData = new FormData();
            formData.append('id', params.hash_id);
            formData.append('logo_file', projectData.logo_file);
            formData.append('name', projectData.name);
            formData.append('email', projectData.email);
            formData.append('brief', projectData.details);
            formData.append('phone', projectData.phone);
            formData.append('theme_color', projectData.theme_color);
            formData.append('website', projectData.website);
            formData.append('facebook', projectData.facebook);
            formData.append('twitter', projectData.twitter);

            let response = await ApiProjects.Update(formData)
            setStatus(response.status)
            setMessage(response.message)
            setErrors(response.errors)

            // Handle the API response as needed
            console.log('API response:', response);
        }
        setValidated(true);
        setLoading(false);
    };

    return (
        <LayoutProjects currentRootName="Update"  >
            {isPageLoading ? (
                <PageLoading />
            ) : (
                <Container fluid="md">
                    <Card className="shadow-sm p-3 mb-5 rounded border border-0">
                        <Card.Body className="d-flex">
                            <Form noValidate validated={validated} onSubmit={handleSubmit} className="w-100" >
                                <HelparAlert status={status} message={message} errors={errors} ></HelparAlert>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2"> </Form.Label>
                                    <Col sm="10">
                                        {logoUrl != null ? < Figure >
                                            <Figure.Image
                                                width={100}
                                                height={100}

                                                    src={logoUrl} />
                                        </Figure> : ""
                                        }
                                    </Col>
                                </Form.Group >
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">Logo</Form.Label>
                                    <Col sm="10">
                                        <Form.Control type="file" onChange={handleImageUpload} />
                                    </Col>

                                </Form.Group >
                                <Form.Group as={Row} className="mb-3">

                                    <Form.Label column sm="2">Name</Form.Label>
                                    <Col sm="10">
                                        <Form.Control name="name" value={projectData.name} onChange={handleInputChange} placeholder="Enter name" required />
                                        <Form.Control.Feedback type="invalid">
                                            name is required
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">Phone</Form.Label>
                                    <Col sm="10">
                                        <Form.Control name="phone" value={projectData.phone} onChange={handleInputChange} placeholder="Enter Phone" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">Email</Form.Label>
                                    <Col sm="10">
                                        <Form.Control name="email" value={projectData.email} onChange={handleInputChange} placeholder="Enter email" type="email" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">Website</Form.Label>
                                    <Col sm="10">
                                        <Form.Control name="website" value={projectData.website} onChange={handleInputChange} placeholder="Enter Website" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">Facebook</Form.Label>
                                    <Col sm="10">
                                        <Form.Control name="facebook" value={projectData.facebook} onChange={handleInputChange} placeholder="Enter Facebook" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">Twitter</Form.Label>
                                    <Col sm="10">
                                        <Form.Control name="twitter" value={projectData.twitter} onChange={handleInputChange} placeholder="Enter Twitter" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">Theme Color</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            name="theme_color"
                                            type="color"
                                            id="exampleColorInput"
                                            defaultValue="#1b6ec2"
                                            value={projectData.theme_color}
                                            onChange={handleInputChange}
                                            title="Choose your color"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">About Your Project</Form.Label>
                                    <Col sm="10">
                                        <Form.Control name="brief" value={projectData.brief} onChange={handleInputChange} placeholder="Enter Details" as="textarea" rows={3} />
                                    </Col>
                                </Form.Group>
                                <Button type="submit"
                                    variant="primary" size="lg"
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Loading…' : 'submit'}
                                </Button>

                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
            )}
        </LayoutProjects>
    );
}
export default ProjectCreate;