/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Image, Badge, Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ApiProjects from '../Apis/Projects';
import Loading from '../Helpars/Loading';
import * as Icon from 'react-bootstrap-icons';
import apiAccessModules from '../Apis/AccessModules';
import moment from 'moment';



const AccessCard = (props) => {
    const params = useParams();
    const [readerModalShow, setReaderModalShow] = React.useState(false);
    const [projectsDataHeader, setProjectsDataHeader] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [accessModuleData, setAccessModuleData] = useState({
        description: '--------',
        checkins_maximum: 0,
        checkins_count: 0,
        reader_apps: [],

    });
    useEffect(() => {
        populateBusinessData()
        populateAccessModuleData()
    }, [props.access_module_id])

    const populateBusinessData = async () => {
        const projectData = await ApiProjects.GetById(params.hash_id);
        setProjectsDataHeader(projectData.data)
        const root = document.documentElement;
        root.style.setProperty('--bs-primary', projectData.data.theme_color);
        setLoading(false);
    }
    const populateAccessModuleData = async () => {
        if (props.access_module_id !== 0 && props.access_module_id !== '' && props.access_module_id !== undefined) {
            let response = await apiAccessModules.GetById(params.hash_id, parseInt(props.access_module_id));
            setAccessModuleData(response.data)
        }
    }
    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <Card style={{ fontSize: 13 }}>

                    <Card.Header className="bg-primary text-white m-2 rounded">
                        <div class=" d-flex justify-content-between">
                            <div className="d-flex align-items-center" >
                                {projectsDataHeader && projectsDataHeader.logo_url !== null ?
                                    <Image src={projectsDataHeader.logo_url} width={50}
                                        height={50} rounded /> :
                                    <div style={{ width: 30, height: 30 }} className="d-flex bg-primary border-primary fs-2 text-white fw-bold  align-content-center align-items-center justify-content-center rounded  me-2 mb-3">{projectsDataHeader && projectsDataHeader.name[0].toUpperCase()}</div>
                                }
                                <span className="fw-bold ms-1"  >
                                    {projectsDataHeader && projectsDataHeader.name}</span>
                            </div>
                            <div className="justify-content-end">
                                <strong>Access Name</strong>
                                <div>{props.access_name !== null && props.access_name !== '' && props.access_name !== undefined ? props.access_name : '--------'}</div>
                            </div>

                        </div>
                    </Card.Header>
                    <Card.Body className="text-center ">
                        <Card.Text>
                            <strong>Description:  </strong>
                            {props.description !== '' && props.description !== null ? props.description : accessModuleData.description}
                        </Card.Text>

                        {/* ---------Access Date And Time-----------*/}
                        <Row className="mb-4">
                            <Col>
                                <div >
                                    <span>Start at</span>
                                    <div class="d-flex justify-content-center">
                                        <span className="fs-1">{props.starts_at !== " " && props.starts_at !== '' && props.starts_at !== null ? moment(props.starts_at).date() : "01"}</span>
                                        <div className="d-grid ms-1 align-content-center" style={{ fontSize: 12 }}>
                                            <span>{props.starts_at !== " " && props.starts_at !== '' && props.starts_at !== null ? moment(props.starts_at).format('MMM') : "---"}</span>
                                            <span>{props.starts_at !== " " && props.starts_at !== '' && props.starts_at !== null ? moment(props.starts_at).year() : "----"}</span>
                                        </div>
                                    </div>
                                    <div className="border-top pt-1">
                                        {props.starts_at !== " " && props.starts_at !== '' && props.starts_at !== null ? moment(props.starts_at).format('h:mm A') : "--:-- AM"}
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="text-center">
                                    <span>End at</span>
                                    <div class="d-flex justify-content-center">
                                        <span className="fs-1">{props.ends_at !== " " && props.ends_at !== '' && props.ends_at !== null ? moment(props.ends_at).date() : "01"}</span>
                                        <div className="d-grid ms-1 align-content-center" style={{ fontSize: 12 }}>
                                            <span>{props.ends_at !== " " && props.ends_at !== '' && props.ends_at !== null ? moment(props.ends_at).format('MMM') : "---"} </span>
                                            <span>{props.ends_at !== " " && props.ends_at !== '' && props.ends_at !== null ? moment(props.ends_at).year() : "----"}</span>
                                        </div>
                                    </div>
                                    <div className="border-top pt-1">
                                        {props.ends_at !== " " && props.ends_at !== '' && props.ends_at !== null ? moment(props.ends_at).format('h:mm A') : "--:-- PM"}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {/* ---------End Access Date And Time-----------*/}

                        {/* ---------Checkins-----------*/}
                        <div className="d-flex ms-1 justify-content-center">
                            <div className="mb-4 border">
                                <Row className="border-bottom m-0 p-1">
                                    <Col>
                                        <div >
                                            <span >Maximum</span>
                                            <Badge bg="info" className="ms-2 fw-bold">
                                                <span className="fw-bold fs-6">{props.checkins_maximum !== '' && props.checkins_maximum !== null ? props.checkins_maximum : accessModuleData.checkins_maximum !== null && accessModuleData.checkins_maximum !== undefined ? accessModuleData.checkins_maximum : 0}</span>
                                            </Badge>
                                            <span className="ms-2">Checkins</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="p-2">
                                    <Col>
                                        <div>Used</div>
                                        <Badge bg="danger" className="fw-bold fs-6">
                                            <div>{accessModuleData.checkins_count !== null && accessModuleData.checkins_count !== undefined ? accessModuleData.checkins_count : 0}</div>
                                        </Badge>
                                        <div className="mt-1">Checkins</div>

                                    </Col>
                                    <Col>
                                        <div>Remaining</div>
                                        <Badge bg="success" className="fw-bold fs-6">
                                            <div>{props.checkins_maximum !== '' && props.checkins_maximum !== null ? props.checkins_maximum : accessModuleData.checkins_maximum !== null && accessModuleData.checkins_maximum !== 0 && accessModuleData.checkins_maximum !== '' ? (accessModuleData.checkins_maximum - (accessModuleData.checkins_count !== undefined && accessModuleData.checkins_count !== null ? accessModuleData.checkins_count : 0)) : 0}</div>
                                        </Badge>
                                        <div className="mt-1">Checkins</div>

                                    </Col>
                                </Row>
                            </div>
                        </div>
                        {/* ---------End Checkins-----------*/}


                        <Button variant="primary">View QR</Button>
                    </Card.Body>
                    <Card.Footer className="bg-Light text-white m-2 rounded">
                        <div class=" d-flex justify-content-between">
                            <div className="d-flex align-items-center" >
                                <span className="me-1">
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip-2">Readers</Tooltip>}
                                    >
                                        {({ ref, ...triggerHandler }) => (
                                            <Button
                                                variant="primary"
                                                {...triggerHandler}
                                                className="d-inline-flex align-items-center"
                                                onClick={() => setReaderModalShow(true)}
                                            >
                                                <Icon.PhoneFill size={20} ref={ref} />
                                            </Button>
                                        )}
                                    </OverlayTrigger>
                                </span>
                                <span className="me-1">
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip-2">Location</Tooltip>}
                                    >
                                        {({ ref, ...triggerHandler }) => (
                                            <Button
                                                variant="primary"
                                                {...triggerHandler}
                                                className="d-inline-flex align-items-center"
                                                disabled={true}
                                            >
                                                <Icon.PinMapFill size={20} ref={ref} />
                                            </Button>
                                        )}
                                    </OverlayTrigger>
                                </span>
                            </div>


                            <div className="justify-content-end">
                                <Button
                                    variant="primary"
                                    className="d-inline-flex align-items-center"
                                    disabled={true}
                                    size="sm"
                                >
                                    <Icon.EnvelopePlusFill size={20} className="me-1" /> Invite
                                </Button>
                            </div>

                        </div>
                    </Card.Footer>

                </Card>
            )
            }


            <Modal
                show={readerModalShow}
                onHide={() => setReaderModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="text-primary">
                        <Icon.PhoneFill size={25} /> Reraders
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* ---------Readers-----------*/}
                    <div className="text-center">
                        <h5>Access by </h5>
                        <Row className="mb-4 d-flex justify-content-center">
                            {props.reader_apps ? props.reader_apps.map(item => (<Col key={item.id} lg={3} className="border p-2 m-1"><span>{item.name}</span></Col>)) :
                                accessModuleData && accessModuleData.reader_apps &&  accessModuleData.reader_apps.map(item => (<Col key={item.id} lg={3} className="border p-2 m-1"><span>{item.name}</span></Col>))}
                        </Row>
                    </div>
                    {/* ---------End Readers-----------*/}
                </Modal.Body>
            </Modal>
        </>

    );
}
export default AccessCard;


