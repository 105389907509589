/* eslint-disable import/no-anonymous-default-export */
import authService from '../api-authorization/AuthorizeService';
import ApiBase from './Base'

const url = "api/accesses/"

export default {
    async list(hash_id, searchText = '' , dateFrom = '', dateTo = '', reader_id = '', access_module_id = '', form_id = '', page, per) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const response = await ApiBase.apiBase(url + 'list/' + hash_id + '?search_text=' + searchText + '&date_from=' + dateFrom + '&date_to=' + dateTo +
                '&reader_app_id=' + reader_id + '&access_module_id=' + access_module_id + '&form_id=' + form_id + '&page=' + page + '&per=' + per, token, 'GET')
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Create(access_module_id, hash_id, body) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const urls = url + 'create/' + access_module_id + '/' + hash_id;
            const response = await ApiBase.apiBase(urls, token, 'POST', body)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Delete(id, hash_id) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const urls = url + 'delete/' + id + '/' + hash_id;
            const response = await ApiBase.apiBase(urls, token, 'DELETE')
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async CreateByForm(form_hash_id , body) {
        try {
            const [token] = await Promise.all([authService.getAccessToken()])
            const urls = url + 'form/' + form_hash_id + '/create';
            const response = await ApiBase.apiBase(urls, token, 'POST', body)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
}
